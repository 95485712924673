export const DEFAULT_INCLUDES = [
    'cam_aggregate.entity_id',
    'is_company',
    'name',
    'location',
    'last_viewed',
    'date_added',
    'last_update_attempted_count',
    'last_update_completed_date',
    'is_updating',
    'cam_article_six_month_count.num_negative',
    'cam_article_six_month_count.num_creditAdverse',
    'latest_css_trendline',
    'latest_css_score',
    'css_score_delta',
    'latest_css_date',
];

// Time to debounce the fetch request in Milliseconds.
export const DEFAULT_DEBOUNCE_TIMER = 50;

export const getDefaultSortOptions = (t) => {
    return [
        {
            cls: 'is-company',
            id: 'name',
            label: t('row_header_name', { defaultValue: 'Name' }),
            type: 'ASC',
            selected: true,
        },
        {
            cls: 'location',
            id: 'location',
            label: null,
            type: 'ASC',
            selected: null,
        },
        {
            cls: 'credit-sentiment-score',
            id: 'latest_css_score',
            label: t('row_header_score', { defaultValue: 'Score' }),
            type: 'DESC',
            selected: false,
            // TODO: replace with proper copytext for css explanation!
        },
        {
            cls: 'credit-delta-score',
            id: `css_score_delta`,
            label: t('row_header_daily_change', {
                defaultValue: 'Daily change',
            }),
            type: 'DESC',
            selected: false,
            // TODO: replace with proper copytext for css explanation!
        },
        {
            cls: 'credit-daily-trend',
            label: t('row_header_recent_trend', {
                defaultValue: 'Recent trend',
            }),
            type: 'DESC',
            selected: false,
            // TODO: replace with proper copytext for css explanation!
        },
        {
            cls: 'adverse-score',
            id: 'cam_article_six_month_count.num_creditAdverse',
            label: t('lbl_credit_adverse', { defaultValue: 'Credit adverse' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'negative-score',
            id: 'cam_article_six_month_count.num_negative',
            label: t('lbl_negative', { defaultValue: 'Negative' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'date-added',
            id: 'date_added',
            label: t('row_header_date_added', { defaultValue: 'Date added' }),
            type: 'DESC',
            selected: false,
        },
        {
            cls: 'news-updated',
            id: 'latest_css_date',
            label: t('row_header_last_updated', {
                defaultValue: 'Last updated',
            }),
            type: 'DESC',
            selected: false,
        },
    ];
};

export const DEFAULT_SORT = { name: 'ASC' };

// Max number of rows returned from api
export const DEFAULT_PAGE_SIZE = 30;

export const DEFAULT_PAGE_FILTER = { page: 1, pages: 1, disabled: false };
