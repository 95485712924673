import ArticleHighlightTip from '@bit/modus-moodys.mapulseui.article-highlight-tip';
import ArticleView from '@bit/modus-moodys.mapulseui.article-view';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import requests from '../../requests';
import './styles.scss';
import { useAppContext } from '../../store';
import { useTranslation } from 'react-i18next';
import { getNotRelevantOptions } from '../../pages/PageDetails/defaults';
import logger from '@bit/modus-moodys.mapulseui.logger';
import highlight_tooltip_img from '../../images/highlight_tooltip_img.png';

const {
    getArticle,
    updateArticle,
    updateUserPreferences,
    updateArticleToFolder,
    moveArticleToNotRelavant,
} = requests;

const ArticleViewPanels = {
    // todo: can add removePanel to props
    ArticleViewPanel: ({ item }) => {
        const { stfolders: folders, stActiveFolder: activeFolder } =
            useAppContext();
        const { articleId, stUserInfo, handleManageFolder } = item.props;
        const [articleData, setArticleData] = useState(null);
        const [tipConfirmed, setTipConfirmed] = useState(
            stUserInfo?.preferences.is_snippet_helper ||
                JSON.parse(localStorage.getItem('user_pref_is_snippet_helper'))
        );
        const { t } = useTranslation();
        const moveArticleToFolder = async (params) => {
            if (params?.folderId) {
                try {
                    await updateArticleToFolder({
                        articleId,
                        userFolderId:
                            params.folderId === 'news-feed'
                                ? null
                                : params.folderId,
                    });
                } catch (err) {
                    logger.log(err);
                }
            }
        };
        const markArticleNotRelavant = async (params) => {
            const notRelevantReasons = (params.reasons || [])
                .filter((i) => i.checked)
                .map((i) => i.name);
            try {
                await moveArticleToNotRelavant({
                    articleId,
                    reasons: notRelevantReasons?.length
                        ? notRelevantReasons
                        : ['not-relevant'],
                });
            } catch (err) {
                logger.log(err);
            }
        };
        const handleSave = async (artId, snippets) => {
            try {
                await updateArticle({ articleId: artId, snippets });
            } catch (error) {
                return new Error(error);
            }
        };

        const handleTipClose = async (confirmed) => {
            updateUserPreferences({ isSnippetHelper: confirmed });
            localStorage.setItem('user_pref_is_snippet_helper', `${confirmed}`);
            setTipConfirmed(true);
        };

        useEffect(() => {
            try {
                getArticle({ articleId }).then(({ data }) => {
                    setArticleData(data);
                });
            } catch (error) {
                logger.log(error);
            }
        }, []);

        const onHighlightAction = (action) => {
            // actions are save, copy, remove
            if (action === 'remove') return;
            // GTM
            window.Analytics?.sendEvent?.('highlight_action', {
                articleId: articleData.id,
                entityId: articleData.entityId,
            });
        };
        const getArticleActionProps = () => {
            return {
                disableNotRelevant: activeFolder.id === 'not_relevant',
                labelTexts: {
                    labelSave: t(`lbl_save`),
                    labelNotRelevant: t('lbl_not_relevant'),
                },
                articleNotRelevantFormProps: {
                    checkboxItems: [...getNotRelevantOptions(t)],
                    labelTexts: {
                        submitLabel: t('lbl_done'),
                        skipLabel: t('lbl_skip'),
                        formTitle: t('lbl_not_relevant_title'),
                        placeholder: `${t('lbl_enter')} ${t('lbl_reason')}`,
                    },
                    onSubmit: markArticleNotRelavant,
                },
                folderSelectProps: {
                    onCreateFolderClick: handleManageFolder,
                    onSubmit: moveArticleToFolder,
                    placeholder: t('lbl_search_item', {
                        item: t('lbl_folder'),
                    }),
                    folders: [
                        {
                            label: t('lbl_news_item', { item: t('lbl_feed') }),
                            id: 'news-feed',
                            icon: 'list',
                        },
                        ...folders,
                    ].filter((i) => i.id !== activeFolder.id),
                    labelTexts: {
                        title: t('title_folder_popper'),
                        noFolderTitle: t('lbl_no_folder_title'),
                        createFolderButtonText: `${t('lbl_create')} ${t(
                            'lbl_folder'
                        )}`,
                        lblDone: t('lbl_done'),
                        noFoldersFound: t('lbl_no_folder'),
                    },
                },
            };
        };
        return (
            <div className="article-view-panel panel">
                {articleData && (
                    <ArticleView
                        {...articleData}
                        onSave={handleSave}
                        onHighlightAction={onHighlightAction}
                        containerClass="article-view-panel"
                        labelTexts={{
                            source: t('lbl_source'),
                            copied: t('lbl_copied', { defaultValue: 'Copied' }),
                            copy: t('lbl_copy', { defaultValue: 'Copy' }),
                            save: t('lbl_save', { defaultValue: 'Save' }),
                            remove: t('lbl_remove', { defaultValue: 'Remove' }),
                        }}
                        articleActionProps={{
                            ...getArticleActionProps(),
                        }}
                    />
                )}
                {!tipConfirmed && articleData && (
                    <ArticleHighlightTip
                        imageSrc={highlight_tooltip_img}
                        rightTextContent={
                            <>
                                <h3 className="heading-3">{`${t(
                                    'lbl_tip'
                                )}: ${t('lbl_save_highlights')}`}</h3>
                                <p className="p-1 heading-14">
                                    {t('lbl_save_high_desc')}
                                </p>
                                <p className="p-2 heading-14">
                                    <b>{t('lbl_note') + ':'}</b>{' '}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('lbl_save_high_note'),
                                        }}
                                    ></span>
                                    {/* {} */}
                                </p>
                            </>
                        }
                        labelTexts={{
                            gotIt: t('lbl_got_it'),
                            dontShowAgain: t(`lbl_dont_show_again`),
                        }}
                        onClose={handleTipClose}
                    />
                )}
            </div>
        );
    },
};

ArticleViewPanels.ArticleViewPanel.propTypes = {
    item: PropTypes.instanceOf(PropTypes.object).isRequired,
    removePanel: PropTypes.string.isRequired,
};

export default ArticleViewPanels;
