import React from 'react';
import ReactDOM from 'react-dom';
import TranslationWorker from '@bit/modus-moodys.mapulseui.translation-worker';
import { NotificationsProvider } from '@bit/modus-moodys.mapulseui.notifications';
import AppWrapper from './AppWrapper';
import { Provider } from './store';
import TranslationJson from './translations.json';
import './index.scss';
TranslationWorker.initTranslations(TranslationJson || {});

ReactDOM.render(
    <React.StrictMode>
        <Provider>
            <NotificationsProvider>
                <AppWrapper />
            </NotificationsProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
