import Analytics from '@bit/modus-moodys.mapulseui.analytics';
import DisclaimerModal from '@bit/modus-moodys.mapulseui.disclaimer-modal';
import Footer from '@bit/modus-moodys.mapulseui.footer';
import Header from '@bit/modus-moodys.mapulseui.header';
import PanelWrapper from '@bit/modus-moodys.mapulseui.panel-wrapper';
import RibbonMenuAction from '@bit/modus-moodys.mapulseui.ribbon-menu-action';
import TestingZone from '@bit/modus-moodys.mapulseui.testing-zone';
import UserMenu from '@bit/modus-moodys.mapulseui.user-menu';
import TranslationWorker from '@bit/modus-moodys.mapulseui.translation-worker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.scss';
import panelProps from './flows';
import { getQueryParams } from './helpers';
import { isEnvDev } from './helpers/utils';
import PageAuthError from './pages/PageAuthError';
import PageRouter from './pages/PageRouter';
import PageSplash from './pages/PageSplash';
import requests from './requests';
import { useAppContext } from './store';
import logger from '@bit/modus-moodys.mapulseui.logger';
import logo from './logo.png';
let AnalyticsInstance;

const { login, verify, logout, updateUserPreferences } = requests;
function App() {
    const {
        stRibbonMenu,
        stPanels,
        stUserInfo,
        stAuthenticated,
        actSetPanels,
        actSetUserInfo,
        history,
        stDisclaimerConfirmed,
        actSetDisclaimerConfirmed,
    } = useAppContext();
    const { t } = useTranslation();
    const [authError, setAuthError] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [, setScrollOffset] = useState(0);

    const { panelsModel, addPanel, removePanel, hideFromIndex } = panelProps(
        stPanels,
        actSetPanels
    );
    const handleUserPref = async () => {
        await updateUserPreferences({
            is_satisfaction_utility: 0,
            is_satisfaction_experience: 0,
            date_added: moment(new Date())
                .subtract(2, 'months')
                .format('YYYY-MM-DD HH:mm:ss'),
        });
    };
    const onChange = (locale) => {
        localStorage.setItem(TranslationWorker.keyForLocale, locale);
        window.location.reload();
    };
    // TODO: move side-nav to bit.dev. This is getting too big to live in app.js.
    const testMethods = {
        pageHome: () => history.go('/'),
        pageDetails: () => history.go('/details'),
        pageEntity: () => history.go('/entity'),
        pageError: () => history.go('/error'),
        pagePortfolio: () => history.go('/portfolio'),
        pageSupport: () => history.go('/support'),
        'updateUserPrefs(...)': () => handleUserPref(),
        'Lang EN': () => onChange('en-US'),
        'Lang ES': () => onChange('es'),
    };

    const handleMenuAction = (e, id) => {
        switch (id) {
            case 'val_contact':
                history.go('/support');
                break;
            case 'val_signout':
                logout();
                break;
            case 'val_settings':
                break;
            default:
                break;
        }
    };

    // NB: This method doesn't prevent users from just shutting off their internet and hitting submit or removing the element from the dom.
    // If we really wanted to force them to respond we need a check on all api calls.
    const handleDisclaimerClose = async (confirmed) => {
        const resp = await updateUserPreferences({ isDisclaimer: confirmed });
        logger.log(resp);
        actSetDisclaimerConfirmed(confirmed);
    };

    const handleTransitionPages = () => {
        let page = null;
        if (authError) {
            page = <PageAuthError />;
        } else if (!stAuthenticated) {
            page = <PageSplash />;
        }
        return page;
    };

    useEffect(() => {
        const query = getQueryParams(window.location);
        switch (query.code) {
            case 'auth_error':
                setAuthError(true);
                logger.log('auth error found');
                break;
            default:
                break;
        }
        const handleVerification = async () => {
            try {
                if (query.code === 'auth_error') return;
                const { data } = await verify();
                actSetUserInfo(data);
                setAuthenticated(true);
                AnalyticsInstance = new Analytics({
                    user_id: data?.user_id ?? 'test-user',
                    env: process.env.NODE_ENV ?? 'local',
                    app_name: process.env.REACT_APP_CLIENT_APP_NAME ?? 'test',
                    email: data?.email ?? 'test-user@test.com',
                });
                // // Sharing localstorage variable, to prevent unwanted login_success events on page refresh
                // const isLoginSuccess = window.localStorage.getItem('isLoginSuccess');
                // if(isLoginSuccess) return;
                // window.localStorage.setItem('isLoginSuccess', true);
                AnalyticsInstance.sendEvent('login_success');

                window.Analytics = AnalyticsInstance;
                const redirectValue = window.localStorage.getItem('redirectTo');
                if (redirectValue) {
                    window.localStorage.removeItem('redirectTo');
                    window.location.href = redirectValue;
                }
            } catch (error) {
                if (window.location.href.includes('/details/')) {
                    const redirectValue = window.location.href;
                    window.localStorage.setItem('redirectTo', redirectValue);
                }
                try {
                    // remove local login state
                    // window.localStorage.removeItem('isLoginSuccess')
                    await login();
                } catch (loginErr) {
                    logger.log(loginErr);
                }
            }
        };
        handleVerification();
        setScrollOffset(0);
        setTimeout(() => window.toggleTester && window.toggleTester(), 100);
    }, []);

    // TODO: Move this logic to the panel wrapper.
    useEffect(() => {
        if (stPanels && Array.isArray(stPanels) && stPanels.length > 0) {
            document.body.classList.add('modal-open');
        } else if (document.body.classList.contains('modal-open')) {
            document.body.classList.remove('modal-open');
        }
    }, [stPanels]);
    const items = [
        [
            {
                label: t('user_menu_contact_support', {
                    defaultValue: 'Contact Support',
                }),
                id: 'val_contact',
            },
        ],
        [
            {
                label: t('user_menu_logout', { defaultValue: 'Log out' }),
                id: 'val_signout',
            },
        ],
    ];
    return (
        <div className="app" id="credit-app">
            {authError || !authenticated ? (
                handleTransitionPages()
            ) : (
                <>
                    <div className="side-nav">
                        {stRibbonMenu.map((e) => {
                            const { label, icon, clicked, isActive } = e;
                            return (
                                <RibbonMenuAction
                                    label={label}
                                    icon={icon}
                                    clicked={clicked}
                                    key={`ribbon-menu-option-${label}`}
                                    isActive={isActive}
                                />
                            );
                        })}
                    </div>
                    {isEnvDev() ? <TestingZone methods={testMethods} /> : null}
                    <Header
                        cls="ma-pulse-header"
                        label={t('app_name_credit', {
                            defaultValue: 'NewsEdge Credit',
                        })}
                        rightComponent={
                            <div className="header-menu-wrapper">
                                {stUserInfo && (
                                    <UserMenu
                                        userInfo={stUserInfo}
                                        items={items}
                                        clicked={handleMenuAction}
                                    />
                                )}
                                {/* <LanguageSelector /> */}
                            </div>
                        }
                        onLogoClick={() => {
                            history.push('/');
                        }}
                        logoUrl={logo}
                    />
                    <Router history={history}>
                        <PageRouter />
                    </Router>
                    <Footer
                        label={t('footer_powered_by', {
                            defaultValue: 'Powered by:',
                        })}
                        markup={`${t('lbl_disclaimer', {
                            defaultValue: 'Disclaimer',
                        })}<br><br>${t('block_footer_disclaimer_credit', {
                            currentYear: moment().year(),
                            link: `/copyright`,
                        })}`}
                        logoUrl={logo}
                    />
                    <PanelWrapper
                        cls="credit articlePopupOverride"
                        tag={null}
                        panelsModel={panelsModel}
                        addPanel={addPanel}
                        removePanel={removePanel}
                        currentPanels={stPanels}
                        hideFromIndex={hideFromIndex}
                        containerProps={{
                            labelTexts: {
                                back: t('lbl_back'),
                            },
                        }}
                    />

                    {!stDisclaimerConfirmed ? (
                        <DisclaimerModal
                            title={t('lbl_acc_agreement')}
                            instructions={t('lbl_scroll_to_agree')}
                            content={{ __html: t('lbl_legal_markup') }}
                            checkLabel={t('lbl_i_understand')}
                            btnLabel={t('lbl_submit')}
                            onClose={handleDisclaimerClose}
                        />
                    ) : null}
                </>
            )}
        </div>
    );
}

export default App;
