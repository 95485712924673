import Button from '@bit/modus-moodys.mapulseui.button';
import DynamicTabHeaders from '@bit/modus-moodys.mapulseui.dynamic-tab-headers';
import EntityRowCreditPulse from '@bit/modus-moodys.mapulseui.entity-row-credit-pulse';
import FilteredLabelList from '@bit/modus-moodys.mapulseui.filtered-label-list';
import InfoToolTip from '@bit/modus-moodys.mapulseui.info-tool-tip';
import InputTextWithIcon from '@bit/modus-moodys.mapulseui.input-text-with-icon';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import TwoColTitle from '@bit/modus-moodys.mapulseui.two-col-title';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import { isUserSurveyPopupDisabledCookieExists } from '@bit/modus-moodys.mapulseui.survey-pane-container';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import panelProps from '../../flows';
import useDidMount from '../../hooks/useDidMount';
import useRouteChange from '../../hooks/useRouteChange';
import requests from '../../requests';
import { useAppContext } from '../../store';
import {
    DEFAULT_DEBOUNCE_TIMER,
    DEFAULT_INCLUDES,
    DEFAULT_PAGE_SIZE,
} from './defaults';
import HeatmapLayout from './HeatmapLayout';
import SurveyPane from './SurveyPane';
import './styles.scss';
import logger from '@bit/modus-moodys.mapulseui.logger';
import { asyncTimeout } from '../../helpers/utils';

const { getPortfolio, deleteUserEntity, updateUserWatchlistEntity } = requests;

const entityHasNewData = (entity) => {
    const { lastViewed, latestArticlePublishDate } = entity;
    const utcFormat = 'YYYY-MM-DDTHH:mm:SSZ';

    const hasBeenViewed = () =>
        !!DateViewer.getDateMomentObj(lastViewed, utcFormat);
    const viewedAfterUpdate = () => {
        const momentLastViewed = DateViewer.getDateMomentObj(
            lastViewed,
            utcFormat
        );
        const momentLastPublishDate = DateViewer.getDateMomentObj(
            latestArticlePublishDate,
            utcFormat
        );
        return (
            !momentLastPublishDate ||
            momentLastViewed.isSameOrAfter(momentLastPublishDate)
        );
    };

    return !!(!hasBeenViewed() || !viewedAfterUpdate());
};

const PagePortfolio = ({ cls, id, tag }) => {
    const _cls = `page page-portfolio ${cls || ''}`;
    const timeoutId = useRef(null);
    const hasRendered = useRef(true);
    const [loading, setLoading] = useState(true);
    const [isHeatmapActive, setHeatmapActive] = useState(false);
    const [search, setSearch] = useState('');
    const [entities, setEntities] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const { actSetWatchlistEditing } = useAppContext();
    const { t } = useTranslation();
    const {
        history,
        stPanels,
        actSetPanels,
        actSetEntityDetails,
        actSetDefaultPortfolioFilters,
        stDefaultPortfolioFilters,
        resetPortfolioFilters,
        stUserInfo,
    } = useAppContext();
    const [pageData, setPageData] = useState(
        stDefaultPortfolioFilters.pageData
    );
    const [filters, setFilters] = useState(stDefaultPortfolioFilters.filters);
    const [activeWatchlist, setActiveWatchlist] = useState([
        ...stDefaultPortfolioFilters.activeWatchlist,
    ]);
    const [availableWatchlists, setAvailableWatchlists] = useState([]);
    const [showSurveyPane, setShowSurveyPane] = useState(false);
    const { addPanel } = panelProps(stPanels, actSetPanels);

    const statePreserver = (toPreserve) => {
        // eslint-disable-next-line no-debugger
        if (toPreserve) {
            actSetDefaultPortfolioFilters({
                filters: [...filters],
                pageData,
                activeWatchlist,
            });
        } else {
            resetPortfolioFilters();
        }
    };

    useRouteChange(statePreserver);
    const handleSort = () => {
        const { type, id: col } = filters.find(
            (filter) => filter.selected === true
        );
        return { [col]: type };
    };

    const fetch = async (setPage = null) => {
        try {
            const { page, pages, data, watchlist } = await getPortfolio({
                page: setPage || pageData.page,
                size: DEFAULT_PAGE_SIZE,
                watchlists: activeWatchlist.map((wl) => wl.id),
                sort: handleSort(),
                search,
                include: DEFAULT_INCLUDES,
            });
            if (
                activeWatchlist.length > 0 &&
                !watchlist.some((wl) => wl.id === activeWatchlist[0].id)
            ) {
                logger.log('watchlist no longer exists, going back to default');
                setActiveWatchlist([]);
                actSetWatchlistEditing(null);
            } else {
                setPageData({ page, pages, disabled: false });
                setEntities(data);

                setAvailableWatchlists(watchlist);
                hasRendered.current = false;
                setRefresh(false);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            if (error?.msg === 'watchlist no longer exists') {
                setActiveWatchlist([]);
                actSetWatchlistEditing(null);
            } else {
                setPageData({ ...pageData, disabled: false });
            }
        }
    };

    const debouncedFetch = (...args) => {
        setLoading(true);
        if (timeoutId.current) clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(
            () => fetch(...args),
            DEFAULT_DEBOUNCE_TIMER
        );
    };

    // TODO: add submit button to the removal in the row.
    // TODO: add functionality to the paginated list to only allow a single row to be referenced at a time
    // to prevent multiple remove animations concurrently.
    const handleEntityRemoval = async (entityId) => {
        try {
            if (!entityId)
                throw new Error(
                    `Attempt to remove EntitityId: ${entityId} failed. No id provided.`
                );
            const entityIndex = entities.findIndex(
                (i) => i.entityId === entityId
            );
            if (entityIndex < 0)
                throw new Error(
                    `EntityId: ${entityId} Does not exist in state.`
                );
            setEntities(entities);
            if (activeWatchlist.length > 0) {
                updateUserWatchlistEntity({
                    watchlistId: activeWatchlist[0].id,
                    entities: { [`${entityId}`]: false },
                }).then(() => {
                    debouncedFetch();
                });
            } else {
                deleteUserEntity({ entityId }).then(() => {
                    debouncedFetch();
                });
            }
        } catch (error) {
            logger.log(error);
        }
    };

    const onUserSurveyTypeUpdate = async () => {
        debouncedFetch();
        if (!stUserInfo.surveyPreferences?.showSurvey) return;
        // Check if there is a local cookie to prevent popup shown to user
        if (isUserSurveyPopupDisabledCookieExists()) return;
        await asyncTimeout(2000);
        setShowSurveyPane(true);
    };

    useEffect(() => {
        onUserSurveyTypeUpdate();
    }, [stUserInfo.surveyPreferences?.surveyType]);

    useDidMount(() => {
        debouncedFetch(1);
    }, [filters, activeWatchlist, search]);

    // Replace this with a check to see if there was a difference in pageData.page since last render.
    // If there was then go to pageData.page, else go to page 1. Do this by storing the previous page in a ref.
    useDidMount(() => {
        debouncedFetch();
        window.scrollTo(0, 0);
    }, [pageData.page]);

    useEffect(() => {
        if (refresh === true) debouncedFetch();
    }, [refresh]);

    useDidMount(() => {
        if (stPanels.length === 0) setRefresh(true);
    }, [stPanels]);

    const onAction = (action, params) => {
        const {
            entityId,
            name,
            cssScore,
            cssDelta,
            negativeCount,
            creditAdverseCount,
        } = params;
        const startDate = moment()
            .utc()
            .subtract(6, 'month')
            .format(DateViewer.currentDateFormat);
        const endDate = moment.utc().format(DateViewer.currentDateFormat);

        if (['click', 'counts_neg', 'counts_ca'].includes(action)) {
            // GTM
            window.Analytics?.sendEvent?.('open_entity', { entityId });
        }
        switch (action) {
            case 'click':
                actSetEntityDetails({
                    entityId,
                    name,
                    cssScore,
                    cssDelta,
                    counts: {
                        negative: negativeCount,
                        creditAdverse: creditAdverseCount,
                    },
                });
                history.go(`/details/${entityId}`);
                break;
            case 'edit':
                addPanel('AddEntitiesToWatchlist', {
                    watchlist: activeWatchlist[0],
                });
                break;
            case 'delete':
                handleEntityRemoval(entityId);
                break;
            case 'counts_neg':
                actSetEntityDetails({
                    entityId,
                    name,
                    cssScore,
                    cssDelta,
                    counts: {
                        negative: negativeCount,
                        creditAdverse: creditAdverseCount,
                    },
                });
                history.go(
                    `/details/${entityId}?level=negative&startDate=${startDate}&endDate=${endDate}`
                );
                break;
            case 'counts_ca':
                actSetEntityDetails({
                    entityId,
                    name,
                    cssScore,
                    cssDelta,
                    counts: {
                        negative: negativeCount,
                        creditAdverse: creditAdverseCount,
                    },
                });
                history.go(
                    `/details/${entityId}?level=creditAdverse&startDate=${startDate}&endDate=${endDate}`
                );
                break;
            default:
                break;
        }
    };

    const NoDataFoundConfig = {
        AllCompanies: {
            titleLabel: t('lbl_no_item_found', {
                item: t('lbl_entity_plural'),
            }),
            butonLabel: t('lbl_onboard_entity', { count: 2 }),
            buttonAction: () => history.go('/entity'),
        },
        FilteredLabelList: {
            titleLabel: t('lbl_entity_not_in_watch'),
            butonLabel: t('lbl_add_entity_from_portfolio'),
            buttonAction: () =>
                addPanel('AddEntitiesToWatchlist', {
                    watchlist: activeWatchlist[0],
                }),
        },
    };

    const [noDataFoundConfig, setNoDataFoundConfig] = useState(
        NoDataFoundConfig.AllCompanies
    );

    const tabHeadersConfig = [
        {
            id: 'all-companies',
            label: `${t('lbl_all', { defaultValue: 'All' })} ${t(
                'lbl_company',
                {
                    count: 12,
                    defaultValue: 'Companies',
                }
            )}`,
            active: !activeWatchlist.length,
            onClick: () => {
                setActiveWatchlist([]);
                setNoDataFoundConfig(NoDataFoundConfig.AllCompanies);
            },
        },
        {
            component: (
                <FilteredLabelList
                    title={t('lbl_select_item', {
                        defaultValue: 'Select a watchlist',
                        item: t('lbl_watchlist').toLocaleLowerCase(),
                    })}
                    noOptionText={t('lbl_no_item_found', {
                        item: t('lbl_watchlist'),
                    })}
                    labelTexts={{
                        noResultsFound: t('lbl_no_item_found', {
                            item: t('lbl_results').toLocaleLowerCase(),
                        }),
                    }}
                    cls="tab-style space-right"
                    placeholder={t('pl_search', { defaultValue: 'Search' })}
                    onSelect={(item) => {
                        setActiveWatchlist([item]);
                        actSetWatchlistEditing(item);
                        setNoDataFoundConfig(
                            NoDataFoundConfig.FilteredLabelList
                        );
                    }}
                    active={activeWatchlist.length > 0}
                    initialValue={activeWatchlist[0] || {}}
                    items={availableWatchlists}
                    key="watchlist-labeled-list"
                />
            ),
        },
        {
            component: (
                <div key="manage-portfolios" className="no-state space-right">
                    <button
                        id="no-padding"
                        className="tab-handler tab-header link-style"
                        type="button"
                        onClick={() => addPanel('CreateOrEdit')}
                    >
                        {`${t('lbl_manage_item', {
                            item: t('lbl_my_portfolio'),
                        })}`}
                        {/* {t('lbl_ManagePortfolio', { defaultValue: 'Manage portfolios' })} */}
                    </button>
                </div>
            ),
        },
    ];
    return (
        <div className={_cls} id={id} data-tag={tag}>
            <div className="title-ribbon">
                <TwoColTitle
                    rightComponent={
                        <>
                            <InputTextWithIcon
                                widthPx={290}
                                placeholder={t('pl_search_by', {
                                    defaultValue:
                                        'Search by entity or location',
                                })}
                                submit={setSearch}
                                valueChanged={(val) => {
                                    if (val.length === 0) {
                                        setSearch('');
                                    }
                                }}
                                isDisabled={loading}
                            />
                        </>
                    }
                />
                <DynamicTabHeaders items={tabHeadersConfig} />
            </div>
            <div className="page-content">
                <div className="clipping-box">
                    {!hasRendered.current && entities.length === 0 ? (
                        <div className="no-entities-in-watchlist">
                            <div className="cont">
                                <p className="bold heading-4">
                                    {noDataFoundConfig.titleLabel ??
                                        'No Entities Found.'}
                                </p>
                                <div className="btn-container">
                                    <Button
                                        label={
                                            noDataFoundConfig.butonLabel ??
                                            'Onboard Entities'
                                        }
                                        isPrimary
                                        onClick={
                                            noDataFoundConfig.buttonAction ??
                                            (() => history.go('/entity'))
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="table-title-container">
                                <p className="table-title heading-2 bold">
                                    {t('lbl_news_item', {
                                        defaultValue: `News Sentiment`,
                                        item: t('lbl_sentiment'),
                                    })}
                                </p>
                                <span className="table-title-css-note tertiary-text">
                                    {`${t('lbl_css', {
                                        defaultValue: 'Credit Sentiment Score',
                                    })}`}
                                    {/* <div style={{ width: 5 }} /> */}
                                    <InfoToolTip
                                        text={t('lbl_css_descr', {
                                            defaultValue:
                                                'Credit Sentiment Score is a measure of the amount of negative credit-relevant news found.',
                                        })}
                                    />
                                    {/* <SvgLib type="information" /> */}
                                </span>
                                <span className="table-title-adverse-note tertiary-text">
                                    {`${t('lbl_articles_since', {
                                        defaultValue:
                                            'Articles from the last 6 months',
                                        n: 6,
                                    })}`}
                                </span>
                            </div>
                            <PaginatedList
                                cls="portfolio-entity-table"
                                id="entity-row-table"
                                tag="entity-row-table"
                                Row={EntityRowCreditPulse}
                                rowData={entities.map((entity) => ({
                                    ...entity,
                                    hasNewData: entityHasNewData(entity),
                                    labelTexts: {
                                        deleteTitle: t('lbl_delete_item', {
                                            defaultValue: 'Delete this entity',
                                            item: t('lbl_entity', {
                                                defaultValue: 'Entity',
                                            }).toLocaleLowerCase(),
                                        }),
                                        deleteBody: t('dlg_delete_descr'),
                                        editLabel: t('lbl_edit'),
                                        newLabel: t('lbl_new'),
                                        negative: t('lbl_negative'),
                                        creditAdverse: t('lbl_credit_adverse'),
                                    },
                                }))}
                                filters={filters}
                                setFilters={setFilters}
                                loading={loading}
                                pageData={pageData}
                                setPageData={setPageData}
                                onAction={onAction}
                            />
                        </>
                    )}
                    {isHeatmapActive && <div className="blocking-overlay" />}
                    <HeatmapLayout
                        content={{
                            lblToggleClose: t('lbl_heatmap', {
                                defaultValue: 'Heatmap',
                            }),
                            lblToggleOpen: t('lbl_heatmap', {
                                defaultValue: 'Heatmap',
                            }),
                            title: t('lbl_credit_sentiment', {
                                defaultValue: 'Credit Sentiment',
                            }),
                        }}
                        disabled={loading || !entities.length}
                        onActive={(isActive) => setHeatmapActive(isActive)}
                        adjustHeight={pageData.pages > 1 || false}
                        entityIds={entities.map((item) => item.entityId)}
                    />
                </div>
            </div>
            {showSurveyPane && <SurveyPane />}
        </div>
    );
};

PagePortfolio.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
};

PagePortfolio.defaultProps = {
    cls: '',
    id: '',
    tag: '',
};

export default PagePortfolio;
