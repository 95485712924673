import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputText from '@bit/modus-moodys.mapulseui.input-field';
import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import Button from '@bit/modus-moodys.mapulseui.button';
import { useAppContext } from '../../store';
import requests from '../../requests';
import logger from '@bit/modus-moodys.mapulseui.logger';

const EditWatchlistLabel = ({ removePanel, item }) => {
    const { id, label } = item.props.watchlist;
    const [watchlistName, setWatchlistName] = useState(label);
    const [error, setError] = useState();
    const { actSetWatchlistEditing } = useAppContext();
    const { t } = useTranslation();
    const inputRef = useRef();

    const updateWatchlist = async () => {
        requests
            .updateUserWatchlist({
                userWatchlistId: id,
                data: { label: watchlistName },
            })
            .then(() => {
                setError(null);
                actSetWatchlistEditing({ label: watchlistName, id });
                removePanel('EditWatchlistLabel');
            })
            .catch((err) => {
                setError(err.error);
                logger.log('error in label ', err);
            });
    };

    useEffect(() => {
        inputRef.current.value = watchlistName;
    }, []);

    return (
        <div className="manage-watchlists-create-new manage-watchlists-edit">
            <span className="secondary-text label">{`${t('lbl_watchlist', {
                defaultValue: 'Watchlist',
            })} ${t('row_header_name', { defaultValue: 'Name' })}`}</span>
            <InputText
                placeholder={`${t('lbl_enter', { defaultValue: 'Enter' })} ${t(
                    'lbl_watchlist',
                    {
                        defaultValue: 'watchlist',
                    }
                ).toLocaleLowerCase()} ${t('row_header_name', {
                    defaultValue: 'name',
                }).toLocaleLowerCase()}`}
                onValueChange={(val) => setWatchlistName(val?.target?.value)}
                value={label}
                ref={inputRef}
            />
            {error && <p className="error-message">{error.message}</p>}
            <SubmitButton
                label={`${t('lbl_edit', { defaultValue: 'Edit' })} ${t(
                    'lbl_watchlist',
                    {
                        defaultValue: 'watchlist',
                    }
                ).toLocaleLowerCase()} ${t('row_header_name', {
                    defaultValue: 'name',
                }).toLocaleLowerCase()}`}
                action={updateWatchlist}
            />
            <Button
                type="button"
                isPrimary={false}
                className="secondary-text-link"
                label={t('lbl_cancel', { defaultValue: 'Cancel' })}
                onClick={() => removePanel('EditWatchlistLabel')}
            />
        </div>
    );
};

EditWatchlistLabel.defaultProps = {
    removePanel: () => {
        logger.log('hide from index not declared in props');
    },
    item: {},
};

EditWatchlistLabel.propTypes = {
    removePanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};

export default EditWatchlistLabel;
