export const validNameRegex = /[^a-zA-Z0-9\s]/;
export const debounceFn = (fn, ms) => {
    let timeoutId;
    return () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => fn(), ms);
    };
};

export const isNameValid = (name) => {
    return validNameRegex.test(name);
};

export const isEnvDev = () => {
    return (
        process.env.NODE_ENV === 'local' ||
        process.env.NODE_ENV === 'development'
    );
};

export const capitalize = (lower) =>
    lower.charAt(0).toUpperCase() + lower.substring(1);
export const transformIdToMsg = (id) => {
    if (typeof id !== typeof '' || id.length === 0) return '';
    return id
        .split('_')
        .map((i, index) => (index === 0 ? capitalize(i) : i))
        .join(' ');
};

export const asyncTimeout = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};
