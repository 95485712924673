import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PageError = ({ cls, id, tag }) => {
    const _cls = `page page-error ${cls || ''}`;
    return (
        <div className={_cls} id={id} data-tag={tag}>
            <div className="page-content">
                <div className="title-ribbon">Generic Error Page</div>
                <div className="body-text">Oops something went wrong.</div>
            </div>
        </div>
    );
};

PageError.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
};

PageError.defaultProps = {
    cls: '',
    id: '',
    tag: '',
};
export default PageError;
