import moment from 'moment';

export const DEFAULT_INCLUDES = [
    'cam_article.article_id',
    'article.url',
    'article.title',
    'article.date_published',
    'cam_sentiment.label',
    'article.publication',
    'article.source',
    'article.lang',
    'user_article.date_read',
    'user_article.snippets',
    'cam_article.json_category',
    'article.summary',
    'article.license_fulltext_display_allowed',
];
export const DEFAULT_SORT = { 'article.date_published': 'DESC' };
// NB: The first one is used as the initial value for the sort-by dropdown.
export const DEFAULT_SORT_OPTIONS = (t) => [
    {
        label: t('lbl_sort_new_old'),
        id: 'sort_new_old',
        type: 'DESC',
        sortCol: 'article.date_published',
    },
    {
        label: t('lbl_risk_high_low'),
        id: 'sort_high_low',
        type: 'DESC',
        sortCol: 'cam_article.sentiment_id',
    },
    {
        label: t('lbl_risk_low_high'),
        id: 'sort_low_high',
        type: 'ASC',
        sortCol: 'cam_article.sentiment_id',
    },
];
export const getNotRelevantOptions = (t) => [
    {
        label: t('lbl_article_refer_incorrect_entity'),
        name: 'incorrect-entity',
        id: 'incorrect-entity',
    },
    {
        label: t('lbl_topic_not_relevant'),
        name: 'irrelavant-article-topic',
        id: 'irrelavant-article-topic',
    },
    {
        label: t('lbl_inaccurate_info'),
        name: 'inaccurate-information',
        id: 'inaccurate-information',
    },
    {
        label: t('lbl_incorrect_risk_category'),
        name: 'incorrect-risk-category',
        id: 'incorrect-risk-category',
    },
    {
        label: t('lbl_sentiment_incorrect'),
        name: 'incorrect-sentiment',
        id: 'incorrect-sentiment',
    },
    {
        label: t('lbl_other'),
        name: 'other',
        id: 'other',
    },
];
export const DEFAULT_DATE_RANGE = {
    startDate: '',
    endDate: '',
};

/**
 *
 * @param {string} endDate
 * @param {number} diffAmount
 * @param {string} diffUnit
 * @param {string} format
 * @returns {{startDate:string, endDate: string}} spannedDates
 */
export const getMonthDifferenceDates = (
    endDate,
    diffAmount = 0,
    diffUnit = 'day',
    format
) => {
    const mEndDate = moment.utc(endDate, format);
    const mStartDate = mEndDate.clone().subtract(diffAmount, diffUnit);
    return {
        startDate: mStartDate.format(format),
        endDate: mEndDate.format(format),
    };
};

export const DEFAULT_PAGE_DATA = { page: 1, pages: 1, disabled: false };
export const DEFAULT_DEBOUNCE_TIMER = 50;
export const DEFAULT_PAGE_SIZE = 30;
