import Requests from '@bit/modus-moodys.mapulseui.request-manager';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
const axiosConfig = process.env.REACT_APP_axios
    ? JSON.parse(process.env.REACT_APP_axios)
    : {};
import logger from '@bit/modus-moodys.mapulseui.logger';
const {
    auth: authServiceUrl,
    typeahead,
    onboard,
    portfolio,
    entity,
    article,
    statistic,
    cam,
} = axiosConfig.services;
const requests = new Requests(axiosConfig);

const { get, post } = requests;

const getUniqueArrFromJson = (jsonArr) => {
    try {
        const arr = JSON.parse(jsonArr);
        return typeof arr !== typeof [] ? arr : Array.from(new Set(arr));
    } catch (error) {
        logger.error('Error in getUniqueArrFromJson in requests.js');
        return null;
    }
};

/**manage folder flow apis */

requests.createFolder = async ({ label }) =>
    post(`${entity}/user-folder/create`, { label });
requests.searchFolderByName = async ({ keyword, page }) => {
    const transformer = ({ data: response }) => {
        return { ...response.data };
    };

    return post(
        `${entity}/user-folder/list`,
        { filter_keyword: keyword, page, size: 20 },
        transformer
    );
};
requests.updateFolder = async ({ id, label }) => {
    const transformer = ({ data: response }) => {
        return { ...response.data };
    };

    return post(
        `${entity}/user-folder/update`,
        { label: label, user_folder_id: id },
        transformer
    );
};
requests.deleteFolder = async ({ id, deleteArticles = false }) => {
    const transformer = ({ data: response }) => {
        return { ...response.data };
    };

    return post(
        `${entity}/user-folder/delete`,
        { deleteArticles, user_folder_id: id },
        transformer
    );
};

requests.updateArticleToFolder = async ({ userFolderId, articleId }) => {
    return post(`${entity}/user-folder/update-article`, {
        article_id: articleId,
        user_folder_id: userFolderId,
    });
};

requests.moveArticleToNotRelavant = async ({ reasons, articleId }) => {
    return post(`${entity}/non-relevant/add`, {
        article_id: articleId,
        not_relevant: reasons,
    });
};
// -----

requests.getAuthServiceStatus = async () => get(`${authServiceUrl}/status`);

requests.searchByName = async ({ searchName, page, location }) => {
    const transformer = ({ data: response }) => {
        const newData = response.data.map((ent) => {
            return { key: ent.entityId, ...ent };
        });
        return { ...response.data, data: newData };
    };
    return get(
        `${typeahead}/search-by-name?name=${searchName}&is_company=true${
            page ? `&page=${page}` : ''
        }${location && location.length > 0 ? `&location=${location}` : ''}`,
        {},
        transformer
    );
};

requests.searchById = async ({ orbisId }) => {
    return get(
        `${typeahead}/search-by-id?orbis_id=${orbisId}`,
        {},
        (response) => response.data
    );
};
requests.onboardBulk = async ({ orbisIds }) => {
    return post(
        `${onboard}/onboard-bulk`,
        { orbis_ids: orbisIds },
        (response) => response.data
    );
};
requests.status = async ({ size = 25, page = 1 }) => {
    return post(
        `${onboard}/in-progress`,
        { size, page },
        (response) => response.data
    );
};
requests.retryEntity = async ({ entityId }) => {
    return post(
        `${cam}/update`,
        {
            entity_id: entityId,
            is_reset_date: false,
        },
        (response) => response.data
    );
    // return post (`${cam}`)
};
requests.addEntity = async ({ body }) => {
    const result = post(
        `${onboard}/add-entity`,
        body,
        (response) => response.data
    );
    // GTM
    window.Analytics?.sendEvent?.('add_entity_end');
    return result;
};

// -- Jon testing, to move to request handler
// why is this a post request
// why are we sending app name in the body and param and header
requests.getPortfolio = async ({
    page,
    size,
    include = [],
    watchlists = [],
    search,
    sort,
}) => {
    const transformer = ({ data: response }) => {
        const newData = response.data.map(
            ({
                entity_id: entityId,
                is_company: isCompany,
                name,
                location,
                last_viewed: lastViewed,
                date_added: dateAdded,
                // last_update_attempted_count: updateCount,
                last_update_completed_date: lastUpdateDate,
                is_updating: isUpdating,
                num_negative: negative,
                num_creditAdverse: creditAdverse,
                latest_css_trendline: trendLine,
                latest_css_score: cssScore,
                css_score_delta: cssDelta,
                latest_css_date: latestCSSDate,
                latest_article_publish_date: latestArticlePublishDate,
            }) => ({
                key: `${entityId}-entity-row`,
                entityId,
                id: `${entityId}`,
                isCompany: !!+isCompany,
                name,
                hasNewData:
                    !lastViewed ||
                    (lastUpdateDate && lastUpdateDate > lastViewed),
                location,
                lastViewed,
                dateAdded,
                newsUpdated: latestCSSDate || lastUpdateDate,
                isUpdating: !!+isUpdating,
                negativeCount: negative,
                creditAdverseCount: creditAdverse,
                trendLine: JSON.parse(trendLine),
                cssScore:
                    typeof cssScore === typeof 0
                        ? Math.round(cssScore)
                        : cssScore,
                cssDelta: cssDelta ? Math.round(cssDelta) : cssDelta, //round-off only if valid data.
                watchlists,
                latestCSSDate,
                latestArticlePublishDate,
            })
        );
        const transformedResponse = {
            ...response,
            data: newData,
        };
        return transformedResponse;
    };

    const body = {
        page,
        size,
        filter_watchlist: [...watchlists],
        order_by: sort,
        include,
        filter_keyword: search,
    };
    // filter_keyword cannot be null nor length of 0
    if (!search || (typeof search === typeof '' && search.length === 0))
        delete body.filter_keyword;
    return post(`${portfolio}/portfolio`, body, null, transformer);
};

requests.createWatchlist = async ({ label }) =>
    post(`${portfolio}/create-user-watchlist`, { label });

// any merit is using a put request here?
requests.updateWatchlist = async ({ watchlistId, label }) =>
    post(`${portfolio}/update-user-watchlist`, {
        user_watchlist_id: watchlistId,
        label,
    });
// any merit to using a put request here?
requests.deleteWatchlist = async ({ watchlistId }) =>
    post(`${portfolio}/delete-user-watchlist`, {
        user_watchlist_id: watchlistId,
    });
// any merit to using a put/delete request here?
// why are entities a map here and not an array?
// to update entities in a portfolio watchlist
requests.updateUserWatchlistEntity = async ({ watchlistId, entities }) =>
    post(`${portfolio}/update-user-watchlist-entity`, {
        user_watchlist_id: watchlistId,
        entities,
    });

requests.deleteUserEntity = async ({ entityId }) =>
    post(`${portfolio}/delete-user-entity`, {
        entity_id: entityId,
    });

requests.userWatchlist = async ({ filterKeyword, page }) => {
    const transformer = (response) => {
        const newData = response.data.data.map((entity) => {
            return { key: entity.id, ...entity };
        });
        return { ...response.data, data: newData };
    };

    return get(
        `${portfolio}/user-watchlist?${page ? `page=${page}` : ''}${
            filterKeyword ? `&filter_keyword=${filterKeyword}` : ''
        }`,
        {},
        transformer
    );
};

requests.watchlistPortfolio = async ({ id, page, filterKeyword, sort }) => {
    const transformer = (response) => {
        // There is probably a better way to define is_company, but this information doesn't come up in the API
        const newData = response.data.results.map((entity) => {
            return {
                key: entity.entity_id,
                id: entity.entity_id,
                ...entity,
                is_company: true,
            };
        });
        return { ...response.data, results: newData };
    };

    const body = {
        page,
        user_watchlist_id: id,
        order_by: sort,
    };

    if (filterKeyword) body.filter_keyword = filterKeyword;
    return post(`${portfolio}/watchlist-portfolio/`, body, null, transformer);
};

requests.deleteUserWatchlist = async ({ id }) => {
    const body = {
        user_watchlist_id: id,
    };

    return post(`${portfolio}/delete-user-watchlist`, body, null);
};

requests.updateUserWatchlist = async ({ userWatchlistId, data }) => {
    const body = {
        user_watchlist_id: userWatchlistId,
        ...data,
    };

    return post(`${portfolio}/update-user-watchlist`, body);
};

requests.getEntity = async ({
    entityId,
    page,
    size,
    sort,
    include = [],
    riskLevel,
    riskCategory,
    startDate,
    endDate,
    keyword = [],
    notRelevant = false,
    userFolderId,
}) => {
    const transformer = (response) => {
        const newData = response.data.data.map(
            ({
                article_id: articleId,
                title,
                label: riskSentiment,
                publication,
                source,
                date_published: publishDate,
                date_read: dateRead,
                json_category: jsonCats,
                url,
                lang,
                summary,
                folders,
                license_fulltext_display_allowed,
            }) => ({
                id: articleId,
                entityId,
                articleId,
                title,
                riskSentiment: riskSentiment
                    ? riskSentiment.replace(
                          /([a-z0-9]|(?=[A-Z]))([A-Z])/g,
                          '$1 $2'
                      )
                    : '',
                riskCategories: getUniqueArrFromJson(jsonCats) || [],
                publication,
                source,
                publishDate:
                    publishDate &&
                    DateViewer.getDateText(publishDate.split('T')[0]),
                unread: !dateRead,
                url,
                lang,
                summary,
                folders,
                license_fulltext_display_allowed,
            })
        );
        response.data.data = newData;

        // TODO: replace Faux counts transformer
        delete response.data.counts.date;
        delete response.data.counts.id;
        delete response.data.counts.entity_id;
        delete response.data.counts.num_neutral;
        delete response.data.counts.num_positive;
        response.data.counts.creditAdverse =
            response.data.counts.num_creditAdverse;
        response.data.counts.negative = response.data.counts.num_negative;
        delete response.data.counts.num_creditAdverse;
        delete response.data.counts.num_negative;

        const cssScore = response.data.entity.latest_css_score;

        // TODO: replace faux entity transformer
        response.data.entity = {
            name: response.data.entity.name,
            entityId: response.data.entity.entity_id,
            cssScore:
                typeof cssScore === typeof 0 ? Math.round(cssScore) : cssScore,
            cssDelta: response.data.entity.css_score_delta
                ? Math.round(response.data.entity.css_score_delta)
                : response.data.entity.css_score_delta, //round-off only if valid data.
            trendline: JSON.parse(response.data.entity.latest_css_trendline),
            latestCSSDate: response.data.entity.latest_css_date,
        };

        const sDate = response.data.facets?.date_range?.date_start;
        const eDate = response.data.facets?.date_range?.date_end;
        response.data.filters = response.data.facets ?? {};

        response.data.filters = {
            ...response.data.filters,
            dateRange: {
                // startDate: sDate ? moment.utc(sDate).format('MM-DD-YYYY') : sDate,
                // endDate: eDate ? moment.utc(eDate).format('MM-DD-YYYY') : eDate,
                startDate: sDate
                    ? DateViewer.getDateText(sDate.split('T')[0])
                    : sDate,
                endDate: eDate
                    ? DateViewer.getDateText(eDate.split('T')[0])
                    : eDate,
            },
        };
        return response;
    };
    const body = {
        entity_id: entityId,
        page,
        size,
        order_by: sort,
        include,
        filters: {
            sentiment:
                (typeof riskLevel === typeof [] &&
                    riskLevel.length > 0 &&
                    riskLevel) ||
                undefined,
            category:
                (typeof riskCategory === typeof [] &&
                    riskCategory.length > 0 &&
                    riskCategory) ||
                undefined,
            ...(keyword.length ? { keyword } : {}),
        },
        not_relevant: notRelevant,
        user_folder_id: userFolderId,
    };

    if (
        startDate &&
        typeof startDate === typeof ' ' &&
        startDate.trim().length > 0 &&
        endDate &&
        typeof endDate === typeof ' ' &&
        endDate.trim().length > 0
    ) {
        const mStartDate = DateViewer.getDateMomentObj(startDate);
        const mEndDate = DateViewer.getDateMomentObj(endDate);
        body.filters.date_range = {
            date_start: mStartDate.format('YYYY-MM-DD') + 'T00:00:00.000Z',
            date_end: mEndDate.format('YYYY-MM-DD') + 'T00:00:00.000Z',
        };
    }

    return post(`${entity}/detail`, body, null, transformer);
};

requests.getArticle = ({ articleId }) => {
    const transformer = (response) => {
        const {
            article_id: articleId,
            body,
            date_read: dateRead,
            entity_id: entityId,
            date_expires: expirationDate,
            lang,
            publication,
            date_published: publishDate,
            label: riskSentiment,
            json_category: jsonCats,
            saved_date: savedDate,
            snippets,
            source,
            title,
            url,
            license_fulltext_display_allowed: isAllowed,
        } = response.data;

        const newData = {
            id: `${articleId}`,
            title,
            articleId,
            body,
            dateRead,
            entityId,
            expirationDate,
            lang,
            publication,
            publishDate,
            riskSentiment: riskSentiment || '',
            riskCategories: getUniqueArrFromJson(jsonCats) || [],
            savedDate,
            snippets: snippets || '',
            source,
            url,
            isAllowed: isAllowed === 1 ? true : false,
        };

        return { ...response, data: newData };
    };

    const params = {
        article_id: articleId,
        include: [
            'article.body',
            'cam_article.article_id',
            'user_article.date_read',
            'cam_article.entity_id',
            'article.date_expires',
            'article.lang',
            'article.publication',
            'article.date_published',
            'cam_sentiment.label',
            'article.date_created',
            'user_article.snippets',
            'article.source',
            'article.title',
            'article.url',
            'cam_article.json_category',
            'article.license_fulltext_display_allowed',
        ],
    };
    return get(`${article}/read`, { params }, transformer);
};

requests.getEntityFilters = ({ entityId, filter = [] }) => {
    const body = {
        entity_id: entityId,
        filter,
    };
    return post(`${entity}/filter`, body, null, null);
};

requests.updateArticle = ({ articleId, snippets }) => {
    const body = {
        article_id: articleId,
        snippets,
    };
    return post(`${article}/update`, body, null, null);
};

// TODO: move this to the bit request manager.
requests.updateUserPreferences = ({
    isDisclaimer,
    isSnippetHelper,
    // isSatisfactionUtility,
    // isSatisfactionExperience,
    // dateAdded,
    ...rest
}) => {
    const body = {
        preferences: {
            ...rest,
            is_disclaimer:
                typeof isDisclaimer !== 'undefined' ? +isDisclaimer : undefined,
            is_snippet_helper:
                typeof isSnippetHelper !== 'undefined'
                    ? +isSnippetHelper
                    : undefined,
            ...rest,
        },
    };
    // console.log({ body });
    return post(`${authServiceUrl}/update-user-preference`, body, null, null);
};

requests.getHeatmap = ({ entities, startDate, endDate, interval }) => {
    const transformer = (response) => {
        let dateRange = null;

        const arrayResp = entities.map((entityId) => {
            const series = (response[entityId] || []).reverse();
            dateRange =
                !dateRange && series.length > 0
                    ? series.map(({ date }) => new Date(`${date}T00:00`))
                    : dateRange;
            return { entityId, series };
        });

        return { range: dateRange, data: arrayResp };
    };

    startDate =
        startDate instanceof Date
            ? startDate.toISOString().split('T')[0]
            : startDate;
    endDate =
        endDate instanceof Date ? endDate.toISOString().split('T')[0] : endDate;

    const body = {
        entity_ids: entities,
        date_start: startDate,
        date_end: endDate,
        interval,
    };

    return post(`${portfolio}/heatmap`, body, null, transformer);
};

requests.getSentimentStats = ({
    entityId,
    interval,
    startDate,
    endDate,
    t: tt,
}) => {
    const transformer = (response) => {
        let { stats: arrData, oldest_date: oldestDate } = response.data;
        let scores = [];
        let countNegative = [];
        let countCredAdv = [];
        let dates = [];

        for (let i in arrData) {
            const sample = arrData[i];
            dates.push(sample.date ? sample.date.split('T')[0] : null);
            scores.push(
                typeof sample.score === typeof 0
                    ? Math.round(sample.score)
                    : null
            );
            countCredAdv.push(
                typeof sample.num_level_creditAdverse === typeof 0
                    ? sample.num_level_creditAdverse
                    : 0
            );
            countNegative.push(
                typeof sample.num_level_negative === typeof 0
                    ? sample.num_level_negative
                    : 0
            );
        }

        const t = {
            oldestDate,
            dates,
            scores: [
                {
                    name: tt('lbl_css'),
                    data: scores,
                    showInLegend: false,
                    color: '#5c068c',
                },
            ],
            series: [
                {
                    name: tt('lbl_credit_adverse'),
                    data: countCredAdv,
                    color: '#5c068c',
                    isShown: true,
                    showInLegend: false,
                },
                {
                    name: tt('lbl_negative'),
                    data: countNegative,
                    color: '#e70031',
                    isShown: true,
                    showInLegend: false,
                },
            ],
            sample: arrData,
        };

        return t;
    };

    const params = {
        entity_id: entityId,
        interval,
        date_start: startDate,
        date_end: endDate,
    };

    return get(`${statistic}/all`, { params }, transformer);
};

requests.addAdHocEntity = async (payload) => {
    const result = await post(
        `${onboard}/add-adhoc-entity`,
        {
            ...payload,
            is_company: true,
        },
        (response) => response.data
    );
    // TODO: Any GTM event?
    return {
        entity: result.data?.data,
        response: result.data,
    };
};

export default requests;
