import InputText from '@bit/modus-moodys.mapulseui.input-field';
import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import TabPanel from '@bit/modus-moodys.mapulseui.tab-panel';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import requests from '../../requests';
import { transformIdToMsg } from '../../helpers/utils';
import logger from '@bit/modus-moodys.mapulseui.logger';

const schemaValidationErrorText =
    'Watchlist name cannot contain special characters';
const CreateNewFolder = ({ hideFromIndex, item }) => {
    const [folderName, setFolderName] = useState('');
    const inputref = useRef();
    const { updateFolderItemList } = item.props;
    // const { actSetWatchlistEditing } = useAppContext();
    const [error, setError] = useState(null);
    const [folderCreateSuccess, setFolderCreateSuccess] = useState(false);
    const [folderInputDisabled, setFolderInputDisabled] = useState(false);

    const createFolder = async () => {
        setError(null);
        const resp = await requests.createFolder({ label: folderName });
        return resp;
    };
    const handleChange = (value) => {
        setFolderName(value);
        setFolderInputDisabled(false);
        setFolderCreateSuccess(false);
        // if (isNameValid(value)) setError(schemaValidationErrorText);
        // else setError(null);
    };
    const handleResults = ({ state, data, error: err }) => {
        if (state) {
            const folderId = data.data.folder_id;
            if (folderId)
                updateFolderItemList('add', {
                    id: folderId,
                    label: folderName,
                });
            setFolderInputDisabled(false);
            setFolderCreateSuccess(true);
            setTimeout(() => {
                hideFromIndex(0);
            }, [500]);
        } else {
            if (err.id === 'schema_validation_failed') {
                setError(schemaValidationErrorText);
            } else {
                setError(transformIdToMsg(err.id));
            }
            setFolderInputDisabled(false);
            logger.log('error response:', err);
        }

        // addPanel('AddEntitiesToWatchlist', { watchlist: data.data[0] });
    };

    return (
        <Translation>
            {(t) => (
                <TabPanel cls="manage-folder-create-new">
                    <span className="secondary-text label">
                        {t('lbl_enter_item', { item: t('lbl_folder') })}
                    </span>
                    <InputText
                        label="Folder Name"
                        ref={inputref}
                        placeholder='e.g. "Automotive," "South America"'
                        onValueChange={(val) =>
                            handleChange(val?.target?.value)
                        }
                    />
                    {error && <p className="message error-message">{error} </p>}
                    {!error && folderCreateSuccess && (
                        <p className="message success-message heading-8">
                            {t('lbl_create_folder_success', {
                                defaultValue: 'Folder successfully created',
                            })}
                        </p>
                    )}
                    <div className="btn-container">
                        <SubmitButton
                            label={t('lbl_create')}
                            action={createFolder}
                            cbResult={handleResults}
                            disabled={
                                !(folderName && folderName.length > 0) ||
                                folderInputDisabled
                            }
                        />
                        <button
                            type="button"
                            className="secondary-text-link"
                            label="Cancel"
                            onClick={() => hideFromIndex(0)}
                        >
                            {t('lbl_cancel')}
                        </button>
                    </div>
                </TabPanel>
            )}
        </Translation>
    );
};

CreateNewFolder.defaultProps = {
    hideFromIndex: () => {},
    item: {},
};

CreateNewFolder.propTypes = {
    hideFromIndex: PropTypes.func,
    item: PropTypes.instanceOf(Object),
};

export default CreateNewFolder;
