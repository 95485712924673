import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import moment from 'moment';
import requests from '../../../../requests';

const { getSentimentStats } = requests;

import OptionsSelector from '@bit/modus-moodys.mapulseui.options-selector';
import ChartDateHistogram from '@bit/modus-moodys.mapulseui.chart-dated-histogram';
import ChartLineGraph from '@bit/modus-moodys.mapulseui.chart-line-graph';
import ChartLineHoverState from '@bit/modus-moodys.mapulseui.chart-line-hover-state';
import { ChartStateColumnGenerator } from '@bit/modus-moodys.mapulseui.chart-hover-component';
import DateViewer from '@bit/modus-moodys.mapulseui.date-viewer';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import logger from '@bit/modus-moodys.mapulseui.logger';

const ONE_DAY = 24 * 60 * 60 * 1000;
const DATE_FORMAT = 'YYYY-MM-DD';
const SAMPLE_COUNT = {
    daily: 30,
    weekly: 30 * 7,
    monthly: 24 * 30,
};

const getDateBefore = (date = new Date(), daysAgo = 1) =>
    new Date(date.getTime() - daysAgo * ONE_DAY);
const getDateAfter = (date = new Date(), daysAfter = 1) =>
    new Date(date.getTime() + daysAfter * ONE_DAY);
const getEntityIdFromPageUrl = () =>
    Number(window.location.pathname.split('/')[2]);

const getStartDateByTimescale = (timescale = 'daily', endDate = new Date()) =>
    getDateBefore(endDate, SAMPLE_COUNT[timescale]);
const getNextEndDateByTimescale = (timescale = 'daily', endDate) =>
    getDateAfter(endDate, SAMPLE_COUNT[timescale]);

const TabSentimentAnalysis = ({ className, errorMessage, dateLabelPrefix }) => {
    const _cls = `tab-sentiment-analysis ${className || ''}`;
    const { t } = useTranslation();
    const optionsTimeScale = [
        { value: 'daily', label: t('lbl_daily'), checked: true },
        { value: 'weekly', label: t('lbl_weekly'), checked: false },
        { value: 'monthly', label: t('lbl_monthly'), checked: false },
    ];

    const [timeScale, setTimeScale] = useState(0);
    const [endDate, setEndDate] = useState(
        moment().subtract(1, 'day').toDate()
    );
    const [startDate, setStartDate] = useState(
        getStartDateByTimescale(optionsTimeScale[timeScale].value, endDate)
    );
    const [isFetching, setFetching] = useState(false);
    const [isPrevArrowDisabled, disablePrev] = useState(false);
    const [isNextArrowDisabled, disableNext] = useState(true);
    const [dataBars, setBarData] = useState(null);
    const [dataLine, setLineData] = useState(null);
    const [oldestStatDate, setOldestDate] = useState(new Date('2017-01-01'));
    const [wasFetchError] = useState(false);

    const timeScaleStr = optionsTimeScale[timeScale].value;
    const sDateStr = moment(startDate).format('DD MMM YYYY');
    const eDateStr = moment(endDate).format('DD MMM YYYY');

    const isDateEqualOrGt = (d1, d2) => {
        d1 = new Date(moment(d1).format(DATE_FORMAT));
        d2 = new Date(moment(d2).format(DATE_FORMAT));
        return d1.getTime() >= d2.getTime();
    };

    const handleOptionChange = (option, index) => {
        setTimeScale(index);
        const lastDay = moment().subtract(1, 'day').toDate();
        const newStartDate = getStartDateByTimescale(option.value, lastDay);
        const newEndDate = lastDay;
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        disablePrev(isDateEqualOrGt(newStartDate, lastDay) ? true : false);
        disableNext(true);
    };

    function dateFormatter(target) {
        if (target?.date) {
            if (timeScaleStr === 'weekly') {
                return (
                    'Week of ' +
                    DateViewer.getDateText(target.date, 'MMM Do, YYYY')
                );
            } else if (timeScaleStr === 'monthly') {
                return DateViewer.getDateText(target.date, 'MMM, YYYY');
            }
            return DateViewer.getDateText(target.date, 'ddd, MMM Do, YYYY');
        }
    }

    const handleArrowNav = (dir = 1) => {
        let newStartDate = null,
            newEndDate = null;
        const now = new Date();

        if (dir >= 0) {
            //clicked next
            newStartDate = endDate;
            newEndDate = getNextEndDateByTimescale(timeScaleStr, endDate);
            newEndDate = isDateEqualOrGt(newEndDate, now) ? now : newEndDate;
        } else {
            //clicked previous
            newEndDate = startDate;
            newStartDate = getStartDateByTimescale(timeScaleStr, newEndDate);
            newStartDate = isDateEqualOrGt(oldestStatDate, newStartDate)
                ? oldestStatDate
                : newStartDate;
        }

        //set the arrow states
        disablePrev(
            isDateEqualOrGt(oldestStatDate, newStartDate) ? true : false
        );
        disableNext(isDateEqualOrGt(newEndDate, now) ? true : false);

        //set the date range
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const getMutatedSeries = (newSeries) => {
        // If Series was already rendered and if there were changes
        // If isShown was set to false, after a new fetch this will be persisted
        if (!dataBars?.series?.length) return newSeries;
        if (!newSeries?.length) return newSeries;
        dataBars.series.forEach((previousSeries) => {
            newSeries.forEach((newSeries) => {
                if (newSeries.name !== previousSeries.name) return;
                newSeries.isShown = previousSeries.isShown;
            });
        });
        return newSeries;
    };

    const handleFetch = async () => {
        const interval = timeScaleStr;

        try {
            setFetching(true);
            const response = await getSentimentStats({
                entityId: getEntityIdFromPageUrl(),
                interval,
                startDate,
                endDate,
                t,
            });

            const {
                dates,
                series: sBars,
                scores: sLine,
                oldestDate,
                sample,
            } = response;
            const pBars = {
                dates,
                series: getMutatedSeries(sBars),
                scale: timeScaleStr,
                inProgress: false,
                sample,
            };
            const pLine = {
                dates,
                series: sLine,
                scale: timeScaleStr,
                inProgress: false,
                sample,
            };

            //set the oldest date
            const oldestDateOb = new Date(oldestDate);
            if (oldestDateOb.getTime() !== oldestStatDate.getTime())
                setOldestDate(oldestDateOb);

            //set the data for rendering charts
            setBarData(pBars);
            setLineData(pLine);
        } catch (error) {
            logger.error(error);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        handleFetch();
    }, [timeScale, endDate, startDate]);

    const happyState = useMemo(() => {
        const categoryRegex = new RegExp(/num_category_(.*)/);
        const getStatusLines = (activeSample) => {
            const categoryKeys = Object.keys(activeSample).filter((key) =>
                key.match(categoryRegex)
            );

            const transformedCategoryKeys = categoryKeys
                .map((key) => key.match(categoryRegex)?.[1])
                .map((key) => key.replace(/_/g, ' '));

            logger.log(activeSample);

            return transformedCategoryKeys.map((categoryKey, index) => ({
                label: capitalize(categoryKey),
                count: activeSample[categoryKeys[index]],
            }));
        };
        return (
            <div
                className={classNames(_cls, {
                    'sentiment-tab-in-progress': isFetching,
                })}
            >
                <div className="controls">
                    <OptionsSelector
                        options={optionsTimeScale}
                        onChange={handleOptionChange}
                    />
                    <p className="date-label italic">
                        <b>From: </b>
                        {dateLabelPrefix +
                            (sDateStr || '') +
                            ' - ' +
                            (eDateStr || '')}
                    </p>
                </div>
                <div className="chart-area">
                    <div
                        className={classNames('arrow left', {
                            disabled: isPrevArrowDisabled,
                        })}
                        onClick={() => handleArrowNav(-1)}
                    >
                        <SvgLib type="chevron" />
                    </div>
                    <div className="plot-area">
                        {dataLine ? (
                            <ChartLineGraph
                                hoverContent={ChartLineHoverState(
                                    dataLine?.sample,
                                    dateFormatter
                                )}
                                cls="chart line"
                                {...dataLine}
                                axisFormatter={{
                                    yAxis: (dataPoint) =>
                                        dataPoint.value > 100
                                            ? ' '
                                            : dataPoint.value,
                                }}
                                columnChartOptions={{
                                    yAxis: {
                                        title: {
                                            text: t('lbl_css', {
                                                defaultValue:
                                                    'Credit Sentiment Score',
                                            }),
                                            style: {
                                                fontStyle: 'italic',
                                                letterSpacing: '0.5px',
                                                color: '#75787B',
                                            },
                                        },
                                        lineColor: '#e4e4e4',
                                    },
                                }}
                            />
                        ) : (
                            <div className="chart line empty" />
                        )}
                        {dataBars ? (
                            <ChartDateHistogram
                                hoverComponent={ChartStateColumnGenerator({
                                    rawSample: dataLine?.sample,
                                    hoverLinesCb: getStatusLines,
                                    topTextCb: dateFormatter,
                                    titleCb: () => t('lbl_top_risk_categories'),
                                    cssScoreCb: (activeSample) =>
                                        Math.round(activeSample.score),
                                    isHeatmapTileEnabled: true,
                                })}
                                cls="chart bars"
                                {...dataBars}
                                setBarData={setBarData}
                                columnChartOptions={{
                                    yAxis: {
                                        title: {
                                            text: t('lbl_article_plural', {
                                                defaultValue: 'Articles',
                                            }),
                                            style: {
                                                fontStyle: 'italic',
                                                letterSpacing: '0.5px',
                                                color: '#75787B',
                                            },
                                        },
                                        lineColor: '#e4e4e4',
                                    },
                                }}
                            />
                        ) : (
                            <div className="chart bars empty" />
                        )}
                    </div>
                    <div
                        className={classNames('arrow right', {
                            disabled: isNextArrowDisabled,
                        })}
                        onClick={() => handleArrowNav(1)}
                    >
                        <SvgLib type="chevron" />
                    </div>
                </div>
                {isFetching && <div className="overlay" />}
            </div>
        );
    }, [dataBars, dataLine, isFetching, isPrevArrowDisabled, optionsTimeScale]);

    const errorState = (
        <div className={classNames(_cls, 'error')}>
            <h3>{errorMessage}</h3>
        </div>
    );

    return wasFetchError ? errorState : happyState;
};

TabSentimentAnalysis.propTypes = {
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    dateLabelPrefix: PropTypes.string,
};

TabSentimentAnalysis.defaultProps = {
    className: '',
    errorMessage: 'Oops, something went wrong!',
    dateLabelPrefix: '',
};

export default TabSentimentAnalysis;
