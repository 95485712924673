import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import WatchlistEntityRow from '@bit/modus-moodys.mapulseui.watchlist-entity-row';
import InputTextWithIcon from '@bit/modus-moodys.mapulseui.input-text-with-icon';
import Button from '@bit/modus-moodys.mapulseui.button';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import { useTranslation } from 'react-i18next';
import requests from '../../requests';
import logger from '@bit/modus-moodys.mapulseui.logger';

// eslint-disable no-debugger

// if the label of the watchlist is edited in another panel, the label
// should update here. Watchlist-portfolio api returns entities, but not the label,
// so updating via API won't work. Editing in a panel is a temporary implementation,
// so we should discuss if this is the best approach to keep the label updated
import { useAppContext } from '../../store';

const AddEntitiesToWatchlist = ({ item, addPanel, removePanel }) => {
    const { stWatchlistEditing, actSetWatchlistEditing } = useAppContext();
    const { t } = useTranslation();
    const id = stWatchlistEditing
        ? stWatchlistEditing.id
        : item.props.watchlist.id;
    const label = stWatchlistEditing
        ? stWatchlistEditing.label
        : item.props.watchlist.label;

    if (!stWatchlistEditing) actSetWatchlistEditing({ id, label });
    const [entityRows, setEntityRows] = useState();
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState(null);
    const [sort, getSort] = useState({ name: 'ASC', is_company: 'ASC' });

    const [filters, setFilters] = useState([
        {
            cls: 'is-company',
            id: 'name',
            label: t('row_header_name', { defaultValue: 'Name' }),
            type: 'ASC',
            selected: true,
        },
    ]);
    const [pageData, setPageData] = useState({
        page: 1,
        pages: 1,
        disabled: false,
    });

    const getEntities = async () => {
        setLoading(true);
        const response = await requests.watchlistPortfolio({
            id,
            page: pageData.page,
            filterKeyword: keyword,
            sort,
        });

        setEntityRows(response.results);

        const { page, pages } = response;
        setPageData({ page, pages, disabled: false });
        setLoading(false);
    };

    const handleKeywordChange = (searchTerm) => {
        if (searchTerm.length === 0) {
            setKeyword(searchTerm);
        }
    };

    const updateKeyword = (searchTerm) => {
        setKeyword(searchTerm);
    };

    // TODO: Refactor this, as it is an async request.
    const onAction = (entityToUpdate, checked) => {
        logger.log(entityToUpdate);

        const entityId = entityToUpdate.entity_id;
        const entities = {};
        entities[entityId] = checked;

        const updatedEntities = entityRows.map((ent) => {
            return { ...ent, bulkCheck: false, bulkUncheck: false };
        });

        setEntityRows(updatedEntities);

        return requests.updateUserWatchlistEntity({
            watchlistId: id,
            entities,
        });

        // Add something that updates entities list after checking
    };

    const selectAll = () => {
        const newData = entityRows.map((ent) => {
            return { ...ent, bulkCheck: true };
        });
        setEntityRows([...newData]);
    };

    const deselectAll = () => {
        const newData = entityRows.map((ent) => {
            return { ...ent, bulkUncheck: true };
        });
        setEntityRows([...newData]);
    };

    useEffect(() => {
        const newSort = Object.assign(sort, { name: filters[0]?.type });
        getSort({ ...newSort });
    }, [filters]);

    useEffect(() => {
        getEntities(pageData.page);
    }, [sort]);

    useEffect(() => {
        getEntities();
    }, []);

    useEffect(() => {
        getEntities();
    }, [pageData.page]);

    useEffect(() => {
        getEntities(pageData.page);
    }, [keyword]);

    const onDoneAddEntities = () => {
        removePanel('AddEntitiesToWatchlist');
        window.Analytics?.sendEvent?.('success_add_watchlist');
    };

    return (
        <div className="manage-watchlists-add-entities-to-watchlist second-panel">
            <h1 className="title heading-2" style={{ paddingBottom: '30px' }}>
                {`${t('lbl_add_entites', {
                    defaultValue: 'Add Entities to',
                })} : ${label}`}
                <button
                    className="edit-watchlist-name"
                    type="button"
                    onClick={() => {
                        addPanel('EditWatchlistLabel', item.props);
                    }}
                >
                    <SvgLib type="pencil" />
                </button>
            </h1>
            <p className="subtext secondary-text">
                {t('lbl_watchlist_subtitle', {
                    defaultValue:
                        'Please select the organizations or people that you want to add to this watchlist.',
                })}
            </p>
            <InputTextWithIcon
                placeholder={t('lbl_search_item', {
                    defaultValue: 'Search my portfolio',
                    item: t('lbl_my_portfolio').toLocaleLowerCase(),
                })}
                submit={updateKeyword}
                valueChanged={handleKeywordChange}
            />
            <Button
                cls="inline-button"
                label={t('lbl_done', { defaultValue: 'Done' })}
                onClick={() => onDoneAddEntities()}
            />
            <div className="paginated-list-container">
                <div className="checkbox-bulk-handlers">
                    <button
                        onClick={selectAll}
                        className="text-link"
                        type="button"
                    >
                        {t('lbl_all', { defaultValue: 'All' })}
                    </button>
                    |
                    <button
                        onClick={deselectAll}
                        className="text-link"
                        type="button"
                    >
                        None
                    </button>
                </div>
                <PaginatedList
                    cls=""
                    tag=""
                    filters={filters}
                    setFilters={setFilters}
                    Row={WatchlistEntityRow}
                    rowData={entityRows}
                    onAction={onAction}
                    pageData={pageData}
                    setPageData={setPageData}
                    loading={loading}
                />
            </div>
        </div>
    );
};

AddEntitiesToWatchlist.defaultProps = {
    item: {},
};

AddEntitiesToWatchlist.propTypes = {
    item: PropTypes.instanceOf(PropTypes.Object),
    removePanel: PropTypes.func.isRequired,
    addPanel: PropTypes.func.isRequired,
};

export default AddEntitiesToWatchlist;
