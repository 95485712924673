const { capitalize } = require('../../../helpers/utils');

const getSurveyProps = (t) => {
    return {
        utilityProps: {
            title: t(`lbl_app_useful`, { app_name: t('app_name_credit') }),
            btnLabel: t('lbl_rate'),
            values: {
                minLabel: t('lbl_not_useful'),
                maxLabel: t('lbl_ext_usefull'),
                levels: [
                    { id: 0, label: 0 },
                    { id: 1, label: 1 },
                    { id: 2, label: 2 },
                    { id: 3, label: 3 },
                    { id: 4, label: 4 },
                    { id: 5, label: 5, isInit: true },
                    { id: 6, label: 6 },
                    { id: 7, label: 7 },
                    { id: 8, label: 8 },
                    { id: 9, label: 9 },
                    { id: 10, label: 10 },
                ],
            },
        },
        experienceProps: {
            title: t(`lbl_app_delighted_text`, {
                app_name: t('app_name_credit'),
            }),
            btnLabel: t('lbl_rate'),
            values: {
                minLabel: '',
                maxLabel: '',
                levels: [
                    {
                        id: 'extremely-frustrated',
                        label: t('lbl_ext_frustrated'),
                    },
                    { id: 'mildly-frustrated', label: t('lbl_mild_frustated') },
                    { id: 'not-sure', label: t('lbl_not_sure'), isInit: true },
                    { id: 'mildly-delighted', label: t('lbl_mild_delight') },
                    { id: 'extremely-delighted', label: t('lbl_ext_delight') },
                ],
            },
        },
        notesProps: {
            title: t(`lbl_note_title`, { app_name: t('app_name_credit') }),
            btnLabel: t('lbl_submit'),
            labelTexts: {
                letUsKnowYourThoughts: t('lbl_know_your_thoughts'),
            },
        },
    };
};
module.exports = {
    getSurveyProps,
    utilityProps: {
        title: `On a scale of 1 to 10, how useful is NewsEdge ${capitalize(
            process.env.REACT_APP_CLIENT_APP_NAME
        )} to you?`,
        btnLabel: 'Rate',
        values: {
            minLabel: 'Not useful at all',
            maxLabel: 'Extremely useful',
            levels: [
                { id: 0, label: 0 },
                { id: 1, label: 1 },
                { id: 2, label: 2 },
                { id: 3, label: 3 },
                { id: 4, label: 4 },
                { id: 5, label: 5, isInit: true },
                { id: 6, label: 6 },
                { id: 7, label: 7 },
                { id: 8, label: 8 },
                { id: 9, label: 9 },
                { id: 10, label: 10 },
            ],
        },
    },
    experienceProps: {
        title: `How delighted or frustrated were you by NewsEdge ${capitalize(
            process.env.REACT_APP_CLIENT_APP_NAME
        )} today?`,
        btnLabel: 'Rate',
        values: {
            minLabel: '',
            maxLabel: '',
            levels: [
                { id: 'extremely-frustrated', label: 'Extremely frustrated' },
                { id: 'mildly-frustrated', label: 'Mildly frustrated' },
                {
                    id: 'not-sure',
                    label: 'Not sure / No comment',
                    isInit: true,
                },
                { id: 'mildly-delighted', label: 'Mildly delighted' },
                { id: 'extremely-delighted', label: 'Extremely delighted' },
            ],
        },
    },
    notesProps: {
        title: `What would have made your experience with NewsEdge ${capitalize(
            process.env.REACT_APP_CLIENT_APP_NAME
        )} better?`,
    },
};
