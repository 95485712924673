import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HeatMapTile from '@bit/modus-moodys.mapulseui.heat-map-tile';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import SvgLib from '@bit/modus-moodys.mapulseui.svg-lib';
import requests from '../../../requests';
import logger from '@bit/modus-moodys.mapulseui.logger';

const { getHeatmap } = requests;
const numberOfSamples = 11;
const oneDayMillis = 24 * 3600 * 1000;
const initEndDate = new Date(new Date().getTime());

const HeatmapLayout = ({
    cls,
    id,
    tag,
    content,
    disabled = false,
    onActive,
    adjustHeight = false,
    entityIds = [],
    lastDateAllowed,
}) => {
    const { t } = useTranslation();
    const radioGroup = useRef(null);
    const [isActive, setActive] = useState(false);
    const [isNextDisabled, setNextDisabled] = useState(true);
    const [isPrevDisabled, setPrevDisabled] = useState(false);
    const [endDateForView, setEndDate] = useState(initEndDate);
    const [timeScale, setTimeScale] = useState('daily');
    const [entityList, setEntityList] = useState([]);
    const [datesInView, setDatesInView] = useState([]);
    const [heatmap, setHeatmap] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [radioOptions, setRadioOptions] = useState({
        daily: {
            checked: true,
            label: t('lbl_daily', { defaultValue: 'Daily' }),
        },
        weekly: {
            checked: false,
            label: t('lbl_weekly', { defaultValue: 'Weekly' }),
        },
    });

    const { lblToggleOpen, lblToggleClose, title } = content;

    const lblToggle = isActive ? lblToggleClose : lblToggleOpen;
    const resetCheckBoxState = () => {
        const currOptions = { ...radioOptions };
        for (let i in currOptions) currOptions[i].checked = false;
        currOptions.daily.checked = true;
        setRadioOptions(currOptions);
    };

    const resetState = () => {
        setActive(false);
        onActive(false);
        setNextDisabled(true);
        setPrevDisabled(false);
        setEndDate(initEndDate);
        setDatesInView([]);
        setTimeScale('daily');
        resetCheckBoxState();
    };

    const getDateRangeBasedOnScale = () => {
        const endDate = endDateForView || initEndDate;
        let startDate = null,
            delta = null;

        switch (timeScale) {
            default:
            case 'daily':
                delta = oneDayMillis * numberOfSamples;
                startDate = new Date(endDate.getTime() - delta);
                break;

            case 'weekly':
                delta = 7 * oneDayMillis * numberOfSamples;
                startDate = new Date(endDate.getTime() - delta);
                break;
        }

        return { endDate, startDate, delta };
    };

    const onClickToggle = () => {
        if (isActive) {
            //the user clicked "close", wait for the close transition
            //to take place and then reset the state
            setTimeout(() => resetState(), 600);
        }

        if (!disabled && !isActive) {
            // GTM
            window.Analytics?.sendEvent?.('open_heatmap');
        }

        !isActive && setEntityList(entityIds);
        !disabled && setActive(!isActive);
        onActive(!isActive);
    };

    const onSetTimescale = (newScale) => {
        //handle radio group operations
        newScale = newScale.trim().toLowerCase();
        const currOptions = { ...radioOptions };
        for (let i in currOptions) currOptions[i].checked = false;
        currOptions[newScale].checked = true;
        setRadioOptions(currOptions);

        //if the new time scale that is selected, is different from the current
        //selection, then affect state changes. Reset the end date for the view
        //and ensure that the new time scale is saved
        if (newScale !== timeScale) {
            setEndDate(initEndDate);
            setTimeScale(newScale);
        }
    };

    const fetchHeatmapInfo = async () => {
        if (!entityList || entityList.length === 0) return;
        const { startDate, endDate } = getDateRangeBasedOnScale();
        setLoading(true);

        try {
            const { data: arrHeatmap, range: dateRange } = await getHeatmap({
                entities: entityList,
                startDate,
                endDate,
                interval: timeScale,
            });

            setDatesInView(dateRange);
            setHeatmap(arrHeatmap);
        } catch (error) {
            logger.error('ERROR in heatmap');
        } finally {
            setLoading(false);
        }
    };

    const onChangeDate = (isPrev = true) => {
        const { startDate, endDate, delta } = getDateRangeBasedOnScale();
        let nextEndDateToSet = isPrev
            ? startDate
            : new Date(endDate.getTime() + delta);
        let nextStartDateToSet = new Date(nextEndDateToSet.getTime() - delta);
        let prevDisabled = false,
            nextDisabled = false;

        if (
            isPrev &&
            nextStartDateToSet.getTime() <= lastDateAllowed.getTime()
        ) {
            prevDisabled = true;
        }

        if (
            nextEndDateToSet.setHours(0, 0, 0, 0) >=
            initEndDate.setHours(0, 0, 0, 0)
        ) {
            nextEndDateToSet = initEndDate;
            nextDisabled = true;
        }

        setNextDisabled(nextDisabled);
        setPrevDisabled(prevDisabled);
        setEndDate(nextEndDateToSet);
    };

    const cleanupDatesInView = () => {
        const cleaned = [];

        for (let i = 1; i < datesInView.length; i++) {
            const dCur = datesInView[i];
            const dPrev = datesInView[i - 1];
            const showYear =
                dCur.getFullYear() === dPrev.getFullYear() ? false : true;
            cleaned.push({ date: dCur, showYear });
        }

        if (cleaned.length > 0) {
            cleaned.unshift({
                date: datesInView[0],
                showYear: true,
            });
        }

        return cleaned;
    };

    if (disabled && isActive) {
        setActive(false);
        onActive(false);
        setTimeout(() => resetState(), 400);
    }

    useEffect(() => {
        fetchHeatmapInfo();
    }, [timeScale, endDateForView, entityList]);

    return (
        <div
            className={classNames(
                `heatmap-container ${cls || ''}`,
                isActive && 'active',
                adjustHeight && 'higher-bottom',
                isLoading && 'fetch-in-prog'
            )}
            id={id}
            data-tag={tag}
        >
            <div
                className={classNames('toggle', disabled && 'disabled')}
                onClick={onClickToggle}
            >
                <SvgLib type="arrow" />
                <span>{lblToggle}</span>
            </div>

            <div className="inner-container">
                <div className="title-controls-container">
                    <div className={'heatmap-title'}>
                        <h3>{title}</h3>
                    </div>

                    <div
                        className={classNames(
                            'controls',
                            disabled && 'disabled'
                        )}
                    >
                        <div
                            className="timescale-controls tab-radio"
                            ref={radioGroup}
                        >
                            {Object.keys(radioOptions).map((value) => {
                                const { checked, label } = radioOptions[value];
                                return (
                                    <div
                                        className="option-item"
                                        key={`option-item-${value}`}
                                    >
                                        <input
                                            checked={checked}
                                            onChange={() =>
                                                onSetTimescale(value)
                                            }
                                            id={`opt-${value}`}
                                            type="radio"
                                            name="timescale"
                                            value={value}
                                        ></input>
                                        <label htmlFor={`opt-${value}`}>
                                            {label}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="arrow-controls">
                            <div
                                className={classNames(
                                    'arrow left',
                                    isPrevDisabled && 'disabled'
                                )}
                                onClick={() => onChangeDate(true)}
                            >
                                <SvgLib type="chevron" />
                            </div>
                            <div
                                className={classNames(
                                    'arrow right',
                                    isNextDisabled && 'disabled'
                                )}
                                onClick={() => onChangeDate(false)}
                            >
                                <SvgLib type="chevron" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="date-headers">
                    <ul>
                        {cleanupDatesInView().map(({ date, showYear }, i) => {
                            const mm = date.getMonth() + 1;
                            const dd = date.getDate();
                            const yy = date.getFullYear();

                            return (
                                <li key={`${i}-${date}`} className="cell">
                                    <div
                                        className={classNames(
                                            'yyyy',
                                            showYear ? 'show' : 'hide'
                                        )}
                                    >
                                        {yy}
                                    </div>
                                    <div className="mm-dd">
                                        {mm}/{dd}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="heatmap-rows">
                    {heatmap.map(({ entityId, series }) => {
                        return (
                            <ul className="heatmap-row" key={`key-${entityId}`}>
                                {series.map((item, index) => {
                                    let { date = null, score = null } = item;
                                    score =
                                        typeof score === typeof 0
                                            ? Math.round(score)
                                            : null;
                                    return (
                                        <li
                                            key={`key-${entityId}-${index}-${date}`}
                                            className="cell"
                                        >
                                            <HeatMapTile cssScore={score} />
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

HeatmapLayout.propTypes = {
    cls: PropTypes.string,
    id: PropTypes.string,
    tag: PropTypes.string,
    content: PropTypes.shape({
        lblToggleOpen: PropTypes.string,
        lblToggleClose: PropTypes.string,
        title: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    onActive: PropTypes.func,
    adjustHeight: PropTypes.bool,
    entityIds: PropTypes.arrayOf(PropTypes.number),
    lastDateAllowed: PropTypes.instanceOf(Date),
};

HeatmapLayout.defaultProps = {
    content: {
        lblToggleOpen: 'Heatmap',
        lblToggleClose: 'Heatmap',
        title: 'Credit Sentiment',
    },

    entityIds: [],
    lastDateAllowed: new Date('2018-01-01'),
};

export default HeatmapLayout;
