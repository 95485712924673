/* eslint-disable indent */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import AnimatedRoute from './AnimatedRoute';

// import PageHome from '../PageHome';
import PageEntity from '../PageEntity';
import PageCopyright from '../PageCopyright';
import PageError from '../PageError';
import PagePortfolio from '../PagePortfolio';
import PageSupport from '../PageSupport';
import PageDetails from '../PageDetails';
import useHistoryChange from '../../hooks/useHistoryChange';
import './styles.scss';

function PageRouter({ location }) {
    useHistoryChange(location);

    return (
        <section className="route-section">
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                    {/* <AnimatedRoute path="/" exact slide={30}>
                        <PageHome />
                    </AnimatedRoute> */}
                    <AnimatedRoute path="/entity" slide={30}>
                        <PageEntity />
                    </AnimatedRoute>
                    <AnimatedRoute path="/portfolio" slide={30}>
                        <PagePortfolio />
                    </AnimatedRoute>
                    <AnimatedRoute path="/support" slide={30}>
                        <PageSupport />
                    </AnimatedRoute>
                    <AnimatedRoute path="/details/:id" slide={30}>
                        <PageDetails />
                    </AnimatedRoute>
                    <AnimatedRoute path="/error" slide={30}>
                        <PageError />
                    </AnimatedRoute>
                    <AnimatedRoute path="/copyright" slide={30}>
                        <PageCopyright />
                    </AnimatedRoute>

                    <Redirect to="/portfolio" />
                </Switch>
            </AnimatePresence>
        </section>
    );
}

PageRouter.propTypes = {
    location: PropTypes.shape({
        href: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
        listen: PropTypes.func,
    }).isRequired,
};

export default withRouter(PageRouter);
