import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TypeaheadEntitySearch from '@bit/modus-moodys.mapulseui.typeahead-entity-search';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../store';
import requests from '../../requests';

const parseError = (error) => {
    if (error?.message) return { message: error.message };
    if (error.id === 'no_matches_found')
        return { message: 'No results found.' };
    return error;
};

const Typeahead = ({ addPanel }) => {
    const { stEntities, actSetEntities } = useAppContext();
    const { stTypeaheadError, actTypeaheadError } = useAppContext();
    const { t } = useTranslation();
    const [typeaheadEntityNotExistsName, setTypeaheadEntityNotExistsName] =
        useState(null);
    useEffect(() => {
        actTypeaheadError(null);
    }, []);

    const openEntitySearch = async (panelId, searchTerm) => {
        if (!searchTerm) {
            actSetEntities([]);
            setTypeaheadEntityNotExistsName('');
            return;
        }
        const searchName = searchTerm;
        const _searchName = encodeURIComponent(searchName);
        const entitiesResults = await requests
            .searchByName({ searchName: _searchName })
            .catch((err) => {
                if (err.id !== 'no_matches_found') {
                    actTypeaheadError(parseError(err));
                }
                return err;
            });
        if (entitiesResults && entitiesResults.data) {
            actTypeaheadError(null);
            addPanel(panelId, { entitiesResults, searchName });
        }
    };

    const getEntities = async ({ name }) => {
        if (name.length < 3) {
            actTypeaheadError(null);
            actSetEntities([]);
            return;
        }
        const _name = encodeURIComponent(name);
        const { data: newEntities } = await requests
            .typeaheadSuggestions({ name: _name, isCompany: true })
            .then((data) => {
                setTypeaheadEntityNotExistsName(null);
                return data;
            })
            .catch((err) => {
                if (err.id === 'suggestion_request_failed') {
                    setTypeaheadEntityNotExistsName(name);
                } else {
                    actTypeaheadError(parseError(err));
                }
                actSetEntities([]);
                return err;
            });

        if (Array.isArray(newEntities)) {
            actTypeaheadError(null);
            actSetEntities([...newEntities]);
        }
    };
    // Event handlers for typeahead component:
    // - submit handles when users selet one of typeahead suggestions
    // - keyupHandler means the user stopped typing and a new search should be fired
    // - submitSearch handles the user pressing enter on the search box.
    return (
        <div className="panel1 typeahead-panel">
            <h1 className="title heading-2">
                {t('lbl_enter_item', {
                    item: t('lbl_entity').toLocaleLowerCase(),
                    defaultValue: `Enter entity name:`,
                })}
            </h1>
            <hr style={{ marginBottom: 30 }} />
            <TypeaheadEntitySearch
                submit={(e, ent) =>
                    openEntitySearch('OnBoardSearchByName', ent.name)
                }
                entityList={stEntities}
                keyupHandler={getEntities}
                error={stTypeaheadError}
                submitSearch={(searchTerm) =>
                    openEntitySearch('OnBoardSearchByName', searchTerm || '')
                }
                insertAdHocEntityProps={{
                    entityName: typeaheadEntityNotExistsName,
                    labels: {
                        WarningText: t('lbl_adhoc_desc', {
                            name: typeaheadEntityNotExistsName,
                            defaultValue: `We don’t recognize the entity name "${typeaheadEntityNotExistsName}". Would you like to monitor news for "${typeaheadEntityNotExistsName}" anyway?`,
                        }),
                        SubmitButton: t('lbl_adhoc_confirm', {
                            name: typeaheadEntityNotExistsName,
                            defaultValue: `Yes, add "${typeaheadEntityNotExistsName}" to my portfolio`,
                        }),
                        CancelButton: t('lbl_clear_search', {
                            defaultValue: `Clear & start new search`,
                        }),
                    },
                    onSubmit: async () => {
                        const adhocEntityResponse =
                            await requests.addAdHocEntity({
                                name: typeaheadEntityNotExistsName,
                            });
                        addPanel('OnBoardFinal', adhocEntityResponse);
                    },
                    onClear: () => setTypeaheadEntityNotExistsName(null),
                }}
                isInsertAdHocShown={!!typeaheadEntityNotExistsName}
            />
        </div>
    );
};

Typeahead.propTypes = {
    addPanel: PropTypes.func,
};
Typeahead.defaultProps = {
    addPanel: PropTypes.func,
};

export default Typeahead;
