import React from 'react';
import PropTypes from 'prop-types';
import EntitySearchConfirmationRow from '@bit/modus-moodys.mapulseui.entity-search-confirmation-row';
import SubmitButton from '@bit/modus-moodys.mapulseui.submit-button';
import Button from '@bit/modus-moodys.mapulseui.button';
import { Translation, useTranslation } from 'react-i18next';
import ConfirmationState from '@bit/modus-moodys.mapulseui.confirmation-state';
import './styles.scss';
import requests from '../../requests';
import SearchByName from './SearchByName';
import Typeahead from './Typeahead';
import { getMessageByOnboardResponseId } from './utils';

const panelsByName = {
    OnBoardTypeahead: ({ addPanel }) => {
        return <Typeahead addPanel={addPanel} />;
    },
    OnBoardSearchByName: ({ item, addPanel }) => {
        return <SearchByName item={item} addPanel={addPanel} />;
    },
    OnBoardConfirm: ({ item, addPanel, removePanel }) => {
        const { t } = useTranslation();
        const goToConfirmation = async (entity) => {
            // requests.typeaheadSuggestionsSelect({
            //     orbisId: entity.orbisId,
            // });
            const response = await requests
                .addEntity({
                    body: {
                        // orbis_id: entity.orbisId,
                        entity_id: entity.entityId,
                        app: 'credit',
                    },
                })
                .catch((err) => {
                    return { ...err, message: err.id };
                });

            setTimeout(() => {
                addPanel('OnBoardFinal', { entity, response });
            }, 900);
        };
        return (
            <div className="panel3">
                <h1
                    className="title heading-2"
                    style={{ paddingBottom: '30px' }}
                >
                    {t('title_add_portfolio', {
                        defaultValue:
                            'Would you like to add this entity to your portfolio?',
                    })}
                </h1>
                <hr style={{ marginBottom: '10px' }} />
                <EntitySearchConfirmationRow entity={item.props} />
                <div className="buttons-wrapper">
                    <SubmitButton
                        label={t('lbl_add', { defaultValue: 'Add' })}
                        action={() => goToConfirmation(item.props)}
                    />
                    <Button
                        isPrimary={false}
                        label={t('lbl_cancel', { defaultValue: 'Cancel' })}
                        onClick={() => removePanel('OnBoardConfirm')}
                    />
                </div>
            </div>
        );
    },
    OnBoardFinal: ({ item, hideFromIndex }) => {
        const { entity, response } = item.props;

        return (
            <Translation>
                {(t) => {
                    const { status, message } = getMessageByOnboardResponseId(
                        response.message,
                        t
                    );
                    return (
                        <div className="panel4">
                            <ConfirmationState
                                // entity={entity}
                                // response={response}
                                confirmationText={{
                                    message,
                                    status,
                                    closeLabel: t('lbl_close'),
                                }}
                                onClose={() => hideFromIndex(-1)}
                            >
                                <EntitySearchConfirmationRow entity={entity} />
                            </ConfirmationState>
                        </div>
                    );
                }}
            </Translation>
        );
    },
};

panelsByName.OnBoardTypeahead.propTypes = {
    addPanel: PropTypes.func,
};
panelsByName.OnBoardTypeahead.defaultProps = {
    addPanel: PropTypes.func,
};
panelsByName.OnBoardSearchByName.propTypes = {
    addPanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.string),
};
panelsByName.OnBoardConfirm.propTypes = {
    addPanel: PropTypes.func,
    removePanel: PropTypes.func,
    item: PropTypes.objectOf(PropTypes.object),
};
panelsByName.OnBoardFinal.propTypes = {
    item: PropTypes.objectOf(PropTypes.object),
    hideFromIndex: PropTypes.func,
};

panelsByName.OnBoardTypeahead.defaultProps = {
    addPanel: PropTypes.func,
};
panelsByName.OnBoardSearchByName.defaultProps = {
    addPanel: PropTypes.func,
    item: {},
};
panelsByName.OnBoardConfirm.defaultProps = {
    addPanel: () => {},
    removePanel: () => {},
    item: {},
};
panelsByName.OnBoardFinal.defaultProps = {
    item: {},
    hideFromIndex: () => {},
};

export default panelsByName;
