import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TabPanel from '@bit/modus-moodys.mapulseui.tab-panel';
import PaginatedList from '@bit/modus-moodys.mapulseui.paginated-list';
import EditWatchlistRow from '@bit/modus-moodys.mapulseui.edit-watchlist-row';
import InputTextWithIcon from '@bit/modus-moodys.mapulseui.input-text-with-icon';
import { useAppContext } from '../../store';
import requests from '../../requests';
import logger from '@bit/modus-moodys.mapulseui.logger';

const EditRemoveWatchlist = ({ addPanel }) => {
    const { t } = useTranslation();
    const { actSetWatchlistEditing } = useAppContext();
    const [watchlists, setWatchlists] = useState([]);
    const [loading, setLoading] = useState();
    const [keyword, setKeyword] = useState(null);
    const { stPanels } = useAppContext();
    const [pageData, setPageData] = useState({
        page: 1,
        pages: 2,
        disabled: false,
    });

    const fetchUserWatchlist = () => {
        setLoading(true);
        requests
            .userWatchlist({ page: pageData.page, filterKeyword: keyword })
            .then((response) => {
                setWatchlists([...response.data]);
                const { page, pages } = response;
                setPageData({ page, pages, disabled: false });
                setLoading(false);
            })
            .catch((err) => {
                logger.log(err);
                setLoading(false);
            });
    };

    const handleKeywordChange = (searchTerm) => {
        if (searchTerm.length === 0) {
            setKeyword(searchTerm);
        }
    };

    const updateKeyword = (searchTerm) => {
        setKeyword(searchTerm);
    };

    useEffect(() => {
        logger.log(
            'useEffect to see if it executes when the array of open panels changes',
            stPanels
        );
        const currentPanel = stPanels.find((arr) => arr.id === 'CreateOrEdit');
        if (currentPanel && currentPanel.visible) {
            fetchUserWatchlist();
        }
    }, [stPanels]);

    // useEffect(() => {
    //     fetchUserWatchlist();
    // }, []);

    useEffect(() => {
        fetchUserWatchlist();
    }, [pageData.page]);

    useEffect(() => {
        fetchUserWatchlist(pageData.page);
    }, [keyword]);

    const onAction = (actionType, watchlist) => {
        actSetWatchlistEditing(watchlist);
        switch (actionType) {
            case 'action_edit':
                addPanel('AddEntitiesToWatchlist', { watchlist });
                break;
            case 'action_remove':
                addPanel('DeleteWatchlist', { watchlist });
                break;
            default:
                logger.log(
                    `No action was defined to action type ${actionType}`
                );
                break;
        }
    };
    const getWatchListData = () => {
        return watchlists.map((i) => ({
            ...i,
            editLabel: t('lbl_edit', { defaultValue: 'Edit' }),
            removeLabel: t('remove', { defaultValue: 'Remove' }),
        }));
    };
    return (
        <TabPanel cls="manage-watchlists-edit-or-remove">
            <InputTextWithIcon
                placeholder={t('lbl_search_item', {
                    defaultValue: 'Search my portfolio',
                    item: t('lbl_my_portfolio').toLocaleLowerCase(),
                })}
                submit={updateKeyword}
                valueChanged={handleKeywordChange}
            />
            <PaginatedList
                cls=""
                id="watchlist-row-table"
                tag="watchlist-row-table"
                Row={EditWatchlistRow}
                rowData={[...getWatchListData()]}
                onAction={onAction}
                pageData={pageData}
                setPageData={setPageData}
                loading={loading}
            />
        </TabPanel>
    );
};

EditRemoveWatchlist.defaultProps = {
    addPanel: () => {},
};

EditRemoveWatchlist.propTypes = {
    addPanel: PropTypes.func,
};

export default EditRemoveWatchlist;
