import panelsById from './add-entities/panelsById';
import panelsByName from './add-entities/panelsByName';
import articleViewPanels from './article-view-panels/articleView';
import panelsFilters from './filters/panelsFilters';
import panelsWatchlist from './manageWatchlists/panelsWatchlist';
import panelManageFolder from './manage-folder/panelManageFolder';

const GTMAddPanelEventIds = {
    OnBoardTypeahead: 'add_entity_start',
    OnBoardSearchId: 'add_entity_start',
    CreateOrEdit: 'add_watchlist',
};

const panelProps = (stPanels, actSetPanels) => {
    const showHidden = (list) => {
        setTimeout(() => {
            list.map((item) => {
                const newItem = item;
                newItem.visible = true;
                return item;
            });
            actSetPanels([...list]);
        }, 50);
    };

    const removeHidden = (list) => {
        const onlyVisible = list.filter((item) => item.visible === true);
        setTimeout(() => actSetPanels([...onlyVisible]), 300);
    };

    //  To hide them all use this function with -1 as index
    const hideFromIndex = (index) => {
        if (
            (stPanels[stPanels.length - 1] ?? {}).id !== 'OnBoardFinal' &&
            ['OnBoardTypeahead', 'OnBoardSearchId'].includes(stPanels[0].id)
        ) {
            // GTM
            window.Analytics?.sendEvent?.('add_entity_back');
        }

        const newList = stPanels.map((item, listIndex) => {
            return {
                id: item.id,
                visible: listIndex <= index ? item.visible : false,
                props: item.props,
            };
        });
        //  Step 1 : hide
        actSetPanels([...newList]);
        //  Step 2: remove
        removeHidden(newList);
    };

    const addPanel = (panelId, props) => {
        const panelsList = stPanels;
        const found = stPanels.findIndex((item) => item.id === panelId);
        if (found >= 0) {
            panelsList[found] = { id: panelId, visible: true, props };
            hideFromIndex(found);
        } else {
            panelsList.push({ id: panelId, visible: false, props });
            if (Object.keys(GTMAddPanelEventIds).includes(panelId)) {
                // GTM
                window.Analytics?.sendEvent?.(GTMAddPanelEventIds[panelId]);
            }
        }
        actSetPanels([...panelsList]);
        //  Make it visible with a delay to make a visible transition
        showHidden([...panelsList]);
    };

    const removePanel = (panelId) => {
        const found = stPanels.findIndex((item) => item.id === panelId);
        if (found >= 0) {
            const filteredList = stPanels.map((item) => {
                let retr = {};
                if (item.id === panelId) {
                    retr = { id: item.id, visible: false, props: item.props };
                } else {
                    retr = {
                        id: item.id,
                        visible: item.visible,
                        props: item.props,
                    };
                }
                return retr;
            });

            if (
                ['OnBoardTypeahead', 'OnBoardSearchId'].includes(stPanels[0].id)
            ) {
                // GTM
                window.Analytics?.sendEvent?.('add_entity_back');
            }

            // Step 1 : hide
            actSetPanels([...filteredList]);

            // Step 2: remove
            removeHidden(filteredList);
        }
    };

    return {
        addPanel,
        removeHidden,
        removePanel,
        hideFromIndex,
        panelsModel: {
            ...panelsById,
            ...panelsByName,
            ...panelsWatchlist,
            ...panelsFilters,
            ...articleViewPanels,
            ...panelManageFolder,
        },
    };
};

export default panelProps;
